import { List } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { SortableContext } from '$/components/core/DragAndDrop/SortableContext';
import { ComponentObjectListItem } from '$/pages/EditorPage/components/ComponentSection/ComponentObjectListItem';
import { ComponentLayer } from '$/services/usecases/editor/mapper/editorStatus';

interface IComponentObjectListProps {
  parentIndex: number;
  componentObjects: ComponentLayer[];
}

export const ComponentObjectList: FunctionComponent<
  IComponentObjectListProps
> = ({ componentObjects, parentIndex }) => {
  return (
    <SortableContext
      id={`objectList-${parentIndex}`}
      itemIds={componentObjects.map(
        (object) => `layer-${parentIndex}-${object.index}`,
      )}
    >
      <List>
        {componentObjects
          .slice()
          .reverse()
          .map((componentObject) => (
            <ComponentObjectListItem
              componentObject={componentObject}
              key={componentObject.index}
              index={componentObject.index}
              parentIndex={parentIndex}
            />
          ))}
      </List>
    </SortableContext>
  );
};
