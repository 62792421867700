import { Button, Grid } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { FavoriteFolderItem } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteFolderItem';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';

export const GlobalFavoriteFolders = () => {
  const { t } = useTranslation();
  const folders = useFavoriteStore.useGlobalFavoriteFolders();
  const [hideFolders, setHideFolders] = useState(false);

  if (folders.length === 0) {
    return;
  }

  return (
    <SectionHeadline
      headline={t('favorites.globalFolders')}
      customActionElement={
        <Button
          px='0'
          onClick={() => setHideFolders((state) => !state)}
          variant='ghost'
        >
          {t(hideFolders ? 'general.show' : 'general.hide')}
        </Button>
      }
      gap={hideFolders ? '0' : '3'}
    >
      {!hideFolders && (
        <Grid gap='6' templateColumns={materialTemplateColumn()}>
          <For each={folders}>
            {(folder) => <FavoriteFolderItem key={folder.id} folder={folder} />}
          </For>
        </Grid>
      )}
    </SectionHeadline>
  );
};
