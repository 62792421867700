import { Box, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareSection';
import { ColorRepresentationSelection } from '$/components/core/Editor/ColorSelectionSidebar/ColorRepresentationSelection';
import { CurrentMaterialSection } from '$/components/core/Editor/ColorSelectionSidebar/CurrentMaterialSection';
import { FanPageSelector } from '$/components/core/Editor/ColorSelectionSidebar/FanPageSelector';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderList';
import { FanSelectionFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FanSelectionFilter';
import { MoreSelectionFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/MoreSelectionFilter';
import { FilterSettings } from '$/components/core/Editor/ColorSelectionSidebar/FilterSettings';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { trackEvent } from '$/logger';
import { PropertyOptions } from '$/pages/EditorPage/components/AttributesSection/components/PropertyOptions';
import { useActiveEditorMaterial } from '$/pages/EditorPage/hooks/useActiveEditorMaterial';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { EditorRoute } from '$/routes/Editor/EditorRoute';
import { Material } from '$/services/usecases/materials';
import { mapMaterialTypeToCategory } from '$/utils/piwikUtils';

export const ColorSelectionSidebar = () => {
  const { t } = useTranslation();

  const { projectId } = EditorRoute.useSearch();

  const { hasPermission } = useAuthorization();
  const { setMaterial } = useEditorActions();
  const material = useActiveEditorMaterial();

  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const setMaterialState = useEditorStore.useSetMaterial();

  const onChangeMaterial = (material: Material) => {
    setMaterial(material.id);
    if (activeComponentId != null) {
      setMaterialState(activeComponentId, material.id);
    }

    const category = mapMaterialTypeToCategory(material.type[0]);

    trackEvent(category, 'Click', material.info);
  };

  return (
    <Stack gap='0' w='full'>
      <ShareSection px='4' py='2' type='editor' projectId={projectId} />

      <SidebarDivider />

      <Box h='8' mx='4' mt='3'>
        <SearchInput
          searchLabel={t('inspirationEditor.attributes.search')}
          hideSearchIcon
          useAutofocus={true}
        />
      </Box>

      {material && (
        <Box px='4' pt='6' pb='4'>
          <CurrentMaterialSection material={material} />
        </Box>
      )}

      <SidebarDivider />

      {material &&
        material.type.includes('uni') &&
        !material.collection.includes('test') && (
          <>
            <Box px='4' pt='3' pb='4'>
              <FanPageSelector
                material={material}
                onColorChange={onChangeMaterial}
              />
            </Box>
            <SidebarDivider />
          </>
        )}

      <Box px='4' pt='3' pb='4'>
        <FanSelectionFilter initialMode='uni' />
      </Box>

      <SidebarDivider borderColor='mobileStackDivider' />

      <MoreSelectionFilter />

      <Box px='4' pt='3' pb='4'>
        <ColorRepresentationSelection
          material={material}
          materialTypes={material?.type}
          onColorChange={onChangeMaterial}
        />
      </Box>

      <SidebarDivider borderColor='mobileStackDivider' />

      <Box px='4' pt='3' pb='4'>
        <FilterSettings initialMode='uni' />
      </Box>

      <SidebarDivider />

      {hasPermission('Favorites_Select') && (
        <>
          <Box px='4' pt='3' pb='4'>
            <FavoriteFolderList />
          </Box>
          <SidebarDivider />
        </>
      )}

      <PropertyOptions />

      <SidebarDivider />

      <Box px='4' pt='3' pb='8'>
        <ExportSection mode='editor' />
      </Box>
    </Stack>
  );
};
