import { useQuery } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { MaterialType } from '$/services/mapper/uses';
import { localFilteredMaterialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';

export const useGroupedFavorites = (
  parentFolder: string,
  types?: MaterialType[],
  disableFilters: boolean = false,
  query?: string,
  materialOrder: string[] = [],
) => {
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const favorites = useFavoriteStore.useFavorites();
  const favoriteIds = favorites.map((favorite) => favorite.materialId);
  const { userRole } = useAuthenticationStore();

  const { data, isLoading } = useQuery(
    localFilteredMaterialsQuery({
      ids: favoriteIds,
      filter: disableFilters ? undefined : filter,
      brightnessFilter,
      type: types && types?.length > 0 ? types : undefined,
      query,
      demo: userRole === 'free',
    }),
  );

  const hasNoMatchingMaterials =
    (parentFolder === DEFAULTFOLDERNAME && data?.materials.length === 0) ||
    data?.unfilteredMaterials.length === 0;

  if (isLoading || hasNoMatchingMaterials || favorites.length === 0) {
    return {
      isLoading,
      allFavorites: [] as FavoriteItem[],
      colorFavorites: [] as FavoriteItem[],
      wallFavorites: [] as FavoriteItem[],
      floorFavorites: [] as FavoriteItem[],
      facadeFavorites: [] as FavoriteItem[],
    };
  }

  const sortedFavorites = favorites.sort((a, b) => {
    let aIndex = materialOrder.indexOf(a.materialId);
    let bIndex = materialOrder.indexOf(b.materialId);

    if (aIndex === -1 && bIndex === -1) {
      return a.materialId.localeCompare(b.materialId);
    }

    aIndex = aIndex === -1 ? 1000 : aIndex;
    bIndex = bIndex === -1 ? 1000 : bIndex;
    return aIndex - bIndex;
  });

  const allFavorites = sortedFavorites.reduce<FavoriteItem[]>(
    (existingFavorites, current) => {
      if (!current.parentFolderIds.includes(parentFolder))
        return existingFavorites;

      const material = data?.materials.find(
        (material) => material?.uniqueKey === current.materialId,
      );

      if (material != null) {
        existingFavorites.push({
          material,
          parentFolders: current.parentFolderIds,
        } as FavoriteItem);
      }

      return existingFavorites;
    },
    [],
  );

  const colorFavorites = allFavorites.filter((favorite) =>
    favorite.material.type.includes('uni'),
  );

  const wallFavorites = allFavorites.filter(
    (favorite) =>
      favorite.material.type.includes('wall') &&
      !favorite.material.type.includes('facade'),
  );

  const floorFavorites = allFavorites.filter((favorite) =>
    favorite.material.type.includes('floor'),
  );

  const facadeFavorites = allFavorites.filter((favorite) =>
    favorite.material.type.includes('facade'),
  );

  return {
    isLoading: false,
    allFavorites,
    colorFavorites,
    wallFavorites,
    floorFavorites,
    facadeFavorites,
  };
};
