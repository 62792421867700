import { useShallow } from 'zustand/react/shallow';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { AttributeSidebar } from '$/components/core/Editor/AttributeSidebar';
import { FavoriteFolderView } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderView';
import { SearchResult } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult';
import { ColorSelectionSidebar } from '$/pages/InspirationEditorPage/components/AttributeSection/components/ColorSelectionSidebar';
import { SceneDetails } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { Material } from '$/services/usecases/materials';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

const FavoriteContent = () => {
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  const { activeComponent } = useInspirationEditorStore(
    useShallow((s) => ({ activeComponent: s.getActiveComponent() })),
  );

  if (!activeFolderId) return null;

  return (
    <FavoriteFolderView
      materialTypes={
        activeComponent?.materialTypes ?? ['facade', 'floor', 'wall', 'uni']
      }
      activeMaterialId={activeComponent?.material?.id}
      onClickItem={changeComponentMaterial}
    />
  );
};

const ColorSelectionContent = () => {
  const activeId = useInspirationEditorStore.useActiveComponentId();
  const activeFolderId = useFavoriteStore.useActiveFolderId();

  const { searchVisible } = useMaterialSearchStore(
    useShallow((s) => ({ searchVisible: s.query.length >= 3 })),
  );

  if (activeFolderId || searchVisible) return null;

  return activeId ? <ColorSelectionSidebar /> : <SceneDetails />;
};

const SearchResultContent = () => {
  const query = useMaterialSearchStore.useQuery();
  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  if (query.length < 3) return null;

  const handleResultClick = (material: Material) => {
    changeComponentMaterial(material);
    useMaterialSearchStore.getState().setQuery('');
  };

  return <SearchResult onHandleResultClick={handleResultClick} />;
};

export const InspirationAttributesSection = () => {
  return (
    <AttributeSidebar>
      <FavoriteContent />
      <SearchResultContent />
      <ColorSelectionContent />
    </AttributeSidebar>
  );
};
