import { createRoute } from '@tanstack/react-router';

import { LicensePage } from '$/pages/DashboardPages/pages/LicensePage';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { STANDARD_STALE_TIME } from '$/services/fetcher';

export const LicenseRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/license',
  component: LicensePage,
  staleTime: STANDARD_STALE_TIME,
});
