export const mapLanguageCodeToFlagCode = (languageCode: string): string => {
  switch (languageCode) {
    case 'sl':
      return 'si';
    case 'cs':
      return 'cz';
    default:
      return languageCode;
  }
};
