import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { Droppable } from '$/components/core/DragAndDrop/Droppable';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useFavoriteModal } from '$/pages/DashboardPages/pages/Favorite/hooks/useFavoriteModal';
import { imageEndpointFactory } from '$/services/endpoints/imageEndpoints';
import { FavoriteFolder, useFavoriteStore } from '$/stores/useFavoriteStore';
import { shortenText } from '$/utils/stringUtils';

interface Props {
  folder: FavoriteFolder;
}

export const FavoriteFolderItem: FC<Props> = ({ folder }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isHovered, setIsHovered] = useState(() => isMobile);
  const openModal = useFavoriteModal.useOpenFavoriteModal();
  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();
  const duplicateFolder = useFavoriteStore.useDuplicateFolder();
  const { hasPermission } = useAuthorization();
  const { userRole } = useAuthenticationStore();

  const getMenuOptions = () => {
    if (folder.isGlobalFavoriteFolder) {
      if (!hasPermission('Favorites_Open')) {
        return null;
      }

      return (
        <Menu placement='bottom-end'>
          {({ isOpen }) => (
            <>
              {(isHovered || isOpen) && (
                <MenuButton
                  as={IconButton}
                  layerStyle='floatCardButton'
                  icon={<Icon icon='three_dots' color='text' boxSize='15px' />}
                  onClick={(event) => event.stopPropagation()}
                  variant='text'
                />
              )}
              <MenuList fontSize='sm'>
                <MenuItem
                  onClick={async (event) => {
                    event.stopPropagation();
                    await duplicateFolder(
                      folder.id,
                      t('favorites.duplicatedFolderSuffix'),
                    );
                  }}
                >
                  {t('favorites.duplicateFolder')}
                </MenuItem>
                {userRole === 'superAdmin' && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      openModal('hideFolder', folder.id, folder.name);
                    }}
                  >
                    {t('favorites.hideFolder')}
                  </MenuItem>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      );
    }

    return (
      <Menu placement='bottom-end'>
        {({ isOpen }) => (
          <>
            {(isHovered || isOpen) && (
              <MenuButton
                as={IconButton}
                layerStyle='floatCardButton'
                icon={<Icon icon='three_dots' color='text' boxSize='15px' />}
                onClick={(event) => event.stopPropagation()}
                variant='text'
              />
            )}
            <MenuList fontSize='sm'>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  openModal('renameFolder', folder.id, folder.name);
                }}
              >
                {t('favorites.renameButton')}
              </MenuItem>
              <MenuItem
                onClick={async (event) => {
                  event.stopPropagation();
                  await duplicateFolder(
                    folder.id,
                    t('favorites.duplicatedFolderSuffix'),
                  );
                }}
              >
                {t('favorites.duplicateFolder')}
              </MenuItem>
              {userRole === 'superAdmin' && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    openModal('publishFolder', folder.id, folder.name);
                  }}
                >
                  {t('favorites.publishFolder')}
                </MenuItem>
              )}

              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  openModal('deleteFolder', folder.id, folder.name);
                }}
              >
                {t('favorites.deleteButton')}
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    );
  };

  if (folder.isGlobalFavoriteFolder && folder.hasThumbnail) {
    return (
      <Droppable id={folder.id} isDisabled={folder.isGlobalFavoriteFolder}>
        <VStack
          layerStyle='WelcomeCard'
          pos='relative'
          justify='flex-end'
          overflow='hidden'
          bg='bodyBackground'
          bgImage={imageEndpointFactory.favoriteFolderThumbnail(folder.id)}
          cursor='pointer'
          onClick={() => setActiveFolderId(folder.id)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {getMenuOptions()}
          <HStack
            justify='center'
            w='full'
            maxW='full'
            px='3'
            py='3'
            bg='bodyBackground'
            textOverflow='ellipsis'
            title={folder.name}
          >
            <Icon icon='folder' boxSize='4' />
            <Text>{shortenText(folder.name, 45)}</Text>
          </HStack>
        </VStack>
      </Droppable>
    );
  }

  return (
    <Droppable id={folder.id} isDisabled={folder.isGlobalFavoriteFolder}>
      <VStack
        layerStyle='WelcomeCard'
        pos='relative'
        overflow='hidden'
        bg='bodyBackground'
        cursor='pointer'
        onClick={() => setActiveFolderId(folder.id)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {getMenuOptions()}
        <Icon icon='folder' boxSize='40px' />
        <Box maxW='full' px='3' textOverflow='ellipsis' title={folder.name}>
          {shortenText(folder.name, 45)}
        </Box>
      </VStack>
    </Droppable>
  );
};
