import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { LoadModal } from '$/components/core/Editor/LoadModal';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { uploadProjectImage } from '$/services/usecases/editor';
import { createEditorProject } from '$/services/usecases/projects';

export const EditorLoadModal = () => {
  const user = useAuthenticationStore.useUser();

  const onHandleUpload = async (projectName: string, file: File) => {
    const token = await uploadProjectImage(file);

    if (token == null) return;

    if (user?.id != null) {
      const { response } = await createEditorProject(token, projectName);
      location.search = `?projectId=${response.payload.projectId}`;
    } else {
      useEditorStore.setState({ projectName, freeUserToken: token });
    }
  };

  return <LoadModal onUploadImage={onHandleUpload} />;
};
