import { HStack, Text, VStack } from '@chakra-ui/react';
import { DragEndEvent } from '@dnd-kit/core';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { DnDContext } from '$/components/core/DragAndDrop/DnDContext';
import { SortableContext } from '$/components/core/DragAndDrop/SortableContext';
import { SortableItem } from '$/components/core/DragAndDrop/SortableItem';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { useUpdateBrandCountry } from '$/services/usecases/admin/mutations/useUpdateBrandCountry';
import { adminProducerQuery } from '$/services/usecases/admin/producers';
import { collectionsQuery } from '$/services/usecases/collections';
import { reorderArray } from '$/utils/arrayUtils';

export const ProducerList = () => {
  const [orderedProducers, setOrderedProducers] = useState<
    { id: string; name: string }[]
  >([]);

  const { mutate, isPending } = useUpdateBrandCountry();
  const user = useAuthenticationStore.useUser();
  const { data } = useQuery(adminProducerQuery(user?.managedCountries[0]));
  const collections = useQuery(collectionsQuery);
  const brandCountries = useQuery(brandCountriesQuery);

  const activeBrandCountry = brandCountries.data?.response.payload.find(
    (item) => item.id === user?.managedCountries[0],
  );

  useEffect(() => {
    if (data && data.length) setOrderedProducers(data);
  }, [data]);

  if (!data) return null;

  const onDragEnd = (result: DragEndEvent) => {
    if (result.over == null) {
      return;
    }

    const startIndex = orderedProducers.findIndex(
      (producer) => producer.id === result.active.id,
    );
    const destinationIndex = orderedProducers.findIndex(
      (producer) => producer.id === result.over!.id,
    );

    const items = reorderArray(orderedProducers, startIndex, destinationIndex);

    if (!user || !user.managedCountries.length) return;

    const sortedProducers = items.map((item) => item.id);

    const updatedBrandCountryData: Partial<BrandCountry> = {
      producerIds: sortedProducers,
    };

    if (
      collections.data &&
      collections.data?.length > 0 &&
      activeBrandCountry != null
    ) {
      const sortedCollections = collections.data
        .filter((collection) =>
          activeBrandCountry.collectionIds.includes(collection.intName),
        )
        .sort(
          (a, b) =>
            sortedProducers.indexOf(a.producerIntName) -
            sortedProducers.indexOf(b.producerIntName),
        )
        .map((collection) => collection.intName);

      if (sortedCollections.length > 0)
        updatedBrandCountryData.collectionIds = sortedCollections;
    }

    mutate({
      brandCountry: updatedBrandCountryData,
      id: user?.managedCountries[0],
    });

    setOrderedProducers(items);
  };

  return (
    <VStack alignItems='stretch' gap='4' minW='300px'>
      <DnDContext
        modifiers={['restrictToParentElement', 'limitToVerticalAxis']}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          itemIds={orderedProducers.map((producer) => producer.id)}
          sortingStrategy='vertical'
          isDropDisabled={isPending}
        >
          {orderedProducers.map((producer, index) => (
            <SortableItem id={producer.id} key={producer.id}>
              <HStack
                gap='6'
                px='8'
                py='4'
                bg='background'
                border='1px solid'
                borderColor='border'
                borderRadius='lg'
              >
                <Text>{index + 1}</Text>
                <Text fontWeight='bold'>{producer.name}</Text>
              </HStack>
            </SortableItem>
          ))}
        </SortableContext>
      </DnDContext>
    </VStack>
  );
};
