import {
  Heading,
  Stack,
  Card,
  CardBody,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { useIsMobile } from '$/hooks/useIsMobile';

export const LicensePage = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [licenses, setLicenses] = useState<Record<
    string,
    { licenses: string }
  > | null>(null);

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const file = 'licenses';
        const data =
          (
            (await import(`$/${file}.json`)) as {
              default: Record<string, { licenses: string }>;
            }
          ).default || {};
        setLicenses(data);
      } catch (error) {
        console.warn('licenses.json not found, using fallback:', error);
        setLicenses(null);
      }
    };

    void fetchLicenses();
  }, []);

  return (
    <Stack
      w='full'
      {...(!isMobile && {
        layerStyle: 'DashboardSection',
      })}
    >
      <StyledScrollWrapper>
        <Stack
          gap='0'
          overflowY='auto'
          w='full'
          {...(!isMobile && {
            p: '7',
          })}
        >
          <Heading as='h1' mb='8' fontSize='3xl'>
            {t('license.title')}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing='6'>
            {licenses ? (
              Object.entries(licenses).map(([library, details]) => (
                <Card key={library}>
                  <CardBody>
                    <Text>{library}</Text>
                    <Text>{details.licenses}</Text>
                  </CardBody>
                </Card>
              ))
            ) : (
              <Text>Loading licenses...</Text>
            )}
          </SimpleGrid>
        </Stack>
      </StyledScrollWrapper>
    </Stack>
  );
};
