import {
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { Icon } from '$/components/common/Icon';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { useDebouncedState } from '$/hooks/useDebouncedState';

interface Props extends InputGroupProps {
  searchLabel: string;
  disableDropdown?: boolean;
  hideSearchIcon?: boolean;
  query?: string;
  setQuery?: (query: string) => void;
  useAutofocus?: boolean;
}

export const SearchInput: FC<Props> = ({
  searchLabel,
  disableDropdown = false,
  hideSearchIcon = false,
  query = useMaterialSearchStore.useQuery(),
  setQuery = useMaterialSearchStore.useSetQuery(),
  useAutofocus = false,
  ...props
}) => {
  const toggleDropdown = useMaterialSearchStore.useToggleDropdown();

  const [localQuery, setLocalQuery] = useDebouncedState<string>(
    query,
    (val) => setQuery(val),
    500,
  );

  useEffect(() => {
    setLocalQuery(query);
  }, [query, setLocalQuery]);

  useEffect(() => {
    if (disableDropdown) return;

    toggleDropdown(
      localQuery.length >= 3 && document.activeElement?.id === 'search-input',
    );
  }, [localQuery, disableDropdown, toggleDropdown]);

  return (
    <InputGroup
      alignItems='center'
      justifyContent='center'
      display='flex'
      w='full'
      h={{ base: 'auto', mobile: 'full' }}
      data-group
      {...props}
    >
      {!hideSearchIcon && (
        <InputLeftElement h='full' pointerEvents='none' data-group>
          <Icon
            icon='search'
            w='14px'
            _groupFocusWithin={{ fill: 'text' }}
            fill='neutralText'
          />
        </InputLeftElement>
      )}
      <Input
        h={{ base: '40px', mobile: 'full' }}
        px='2'
        fontSize='xs'
        bg={{ base: 'background', mobile: 'form.inputBackground' }}
        borderRadius={{ base: '8px', mobile: '4px' }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={useAutofocus}
        focusBorderColor='primaryBlue.700'
        id='search-input'
        onBlur={() => toggleDropdown(false)}
        onChange={(e) => setLocalQuery(e.currentTarget.value)}
        onFocus={() => localQuery.length >= 3 && toggleDropdown(true)}
        placeholder={searchLabel}
        value={localQuery}
      />
      {localQuery.length > 0 && (
        <InputRightElement h='full' pr='2'>
          <IconButton
            color='text'
            aria-label='cancel'
            icon={<Icon icon='cancel' w='15px' />}
            onClick={() => setLocalQuery('')}
            variant='text'
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
