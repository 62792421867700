import {
  ZustandHookSelectors,
  createSelectorHooks,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { trackEvent } from '$/logger';
import { queryClient } from '$/services/fetcher';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { FilterGroup } from '$/services/usecases/filter';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { Material } from '$/services/usecases/materials';
import { LayerRotation } from '$/services/usecases/projects/mapper/inspirationProjects';
import { Scene } from '$/services/usecases/scenes';
import { CompleteScene } from '$/services/usecases/scenes/mapper/completeScene';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { intersect } from '$/utils/arrayUtils';
import { mapMaterialTypeToCategory } from '$/utils/piwikUtils';

export type LayerCategory =
  | 'floor'
  | 'wall'
  | 'ceiling'
  | 'outside'
  | 'unknown';

export type Layer = {
  id: string;
  name: string;
  uniqueName: string;
  position: {
    x: number;
    y: number;
  };
  materialCategoryOptions: string[];
  materialTypes: MaterialType[];
  material: Material | null;
  category: LayerCategory;
  views: string[];
  rotation: LayerRotation;
};

export type LayerMarker = {
  id: string;
  position: {
    x: number;
    y: number;
  };
};

export type State = {
  sceneLayersVariants: Layer[][];
  scene: Scene | null;
  completeScene: CompleteScene | null;
  activeViewIndex: number;
  isLoading: boolean;
  activeSceneVariantIndex: number;
  activeComponentId: string | null;
  hoveredComponentId: string | null;
  sceneVariantLabelOrder: string[];
  layerMarkers: LayerMarker[];
  perspectiveFullscreen: boolean;
  viewIds: string[];
  currentProjectId: string | null;
};

type Actions = {
  addSceneVariant: () => void;
  deleteSceneVariant: (index: number) => void;
  setSceneLayersVariants: (sceneVariants: Layer[][]) => void;
  setLayerMarkers: (layerMarkers: LayerMarker[]) => void;
  setScene: (scene: Scene | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  getActiveSceneVariant: () => Layer[];
  setActiveComponentId: (id: string | null) => void;
  setHoveredComponentId: (id: string | null) => void;
  setActiveSceneIndex: (index: number) => void;
  setActiveSceneVariantIndex: (index: number) => void;
  getActiveComponent: () => Layer | undefined;
  changeComponentMaterial: (material: Material) => void;
  changeComponent: (layerId: string) => void;
  togglePerspectiveFullscreen: () => void;
  changeRotationFloor: (rotation: LayerRotation) => void;
  setCurrentProjectId: (projectId: string | null) => void;
};

export const initial: State = {
  sceneLayersVariants: [],
  scene: null,
  completeScene: null,
  isLoading: false,
  activeSceneVariantIndex: 0,
  activeComponentId: null,
  hoveredComponentId: null,
  activeViewIndex: 0,
  sceneVariantLabelOrder: ['A', 'B', 'C'],
  layerMarkers: [],
  perspectiveFullscreen: false,
  viewIds: [],
  currentProjectId: null,
};

export const store = create<State & Actions>()(
  subscribeWithSelector((set, get) => ({
    ...initial,
    addSceneVariant: () => {
      const newVariants = [
        ...get().sceneLayersVariants,
        [...get().getActiveSceneVariant()],
      ];

      set({
        sceneLayersVariants: newVariants,
        activeSceneVariantIndex: newVariants.length - 1,
      });
    },
    deleteSceneVariant: (index) => {
      const newVariants = [...get().sceneLayersVariants];
      newVariants.splice(index, 1);

      const activeIndex = get().activeSceneVariantIndex;

      if (index <= activeIndex && activeIndex !== 0) {
        set({ activeSceneVariantIndex: activeIndex - 1 });
      }

      const label = get().sceneVariantLabelOrder[index];
      const newOrder = [...get().sceneVariantLabelOrder];
      newOrder.splice(index, 1);
      newOrder.push(label);

      set({
        sceneLayersVariants: newVariants,
        sceneVariantLabelOrder: newOrder,
      });
    },
    setSceneLayersVariants: (sceneLayersVariants) => {
      set({ sceneLayersVariants });
    },
    setLayerMarkers: (layerMarkers) => {
      set({ layerMarkers });
    },
    setScene: (scene) => set({ scene }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setActiveComponentId: (id) => {
      set((state) => ({
        activeComponentId: state.activeComponentId === id ? null : id,
        colorSelectionMode: 'off',
      }));
      useCollectionStore.setState({ selectedViewMode: 'grid' });
    },
    setHoveredComponentId: (hoveredComponentId) => set({ hoveredComponentId }),
    getActiveSceneVariant: () => {
      const activeVariantIndex = get().activeSceneVariantIndex;
      const allVariants = get().sceneLayersVariants;
      if (allVariants.length === 0) return [];
      return allVariants[activeVariantIndex];
    },
    getActiveComponent: () => {
      const activeSceneVariant = get().getActiveSceneVariant();
      return activeSceneVariant.find(
        (variant) => variant.id === get().activeComponentId,
      );
    },
    setActiveSceneIndex: (index) => {
      set({
        activeViewIndex: index,
        layerMarkers: get().completeScene?.views[index].components,
      });
    },
    setActiveSceneVariantIndex: (index) => {
      set({ activeSceneVariantIndex: index });
    },
    changeComponentMaterial: (material) => {
      const activeSceneVariant = get().getActiveSceneVariant();

      const newSceneVariant = activeSceneVariant.map((layer) => {
        if (layer.id === get().activeComponentId) return { ...layer, material };
        return layer;
      });

      const { activeSceneVariantIndex, sceneLayersVariants } = get();

      set({
        sceneLayersVariants: [
          ...sceneLayersVariants.slice(0, activeSceneVariantIndex),
          newSceneVariant,
          ...sceneLayersVariants.slice(activeSceneVariantIndex + 1),
        ],
      });

      const category = mapMaterialTypeToCategory(material.type[0]);

      trackEvent(category, 'Click', material.info);
    },
    changeComponent: (layerId: string) => {
      const currentUser = useAuthenticationStore.getState().user;

      const layer = get()
        .getActiveSceneVariant()
        .find((item) => item.id === layerId);

      if (layer == null) return;

      const currentMaterial = layer.material;

      const getMaterialFilters = () => {
        if (currentMaterial == null || currentUser == null) return [];

        const materialFilters: FilterGroup[] = [
          {
            displayName: currentMaterial.producer,
            option: currentMaterial.rawProducer,
            type: 'producer',
          },
          {
            displayName: currentMaterial.collection,
            option: currentMaterial.rawCollection,
            type: 'collection',
          },
        ];

        return materialFilters;
      };

      useCollectionStore.setState({
        filter: undefined,
        filterGroups: getMaterialFilters(),
      });
      useFavoriteStore.setState({ activeFolderId: null });

      const componentTypes = get().getActiveComponent()?.materialTypes ?? [];
      const material = get().getActiveComponent()?.material;
      const initialMode =
        currentUser != null
          ? intersect(componentTypes, material?.type ?? [])[0]
          : 'uni';

      void queryClient
        .ensureQueryData(
          filterOptionsQuery({
            type: initialMode as FilterType,
            filter: getMaterialFilters(),
          }),
        )
        .then((filter) => {
          useCollectionStore.setState({
            filter,
            activeType: initialMode as FilterType,
          });
        });
    },
    changeRotationFloor: (rotation: LayerRotation) => {
      const activeSceneVariant =
        get().sceneLayersVariants[get().activeSceneVariantIndex];

      if (!get().activeComponentId) {
        console.warn('No active component selected for rotation.');
        return;
      }

      const newSceneVariant = activeSceneVariant.map((layer) => {
        if (layer.id === get().activeComponentId) {
          return { ...layer, rotation };
        }
        return layer;
      });

      const { activeSceneVariantIndex, sceneLayersVariants } = get();

      set({
        sceneLayersVariants: [
          ...sceneLayersVariants.slice(0, activeSceneVariantIndex),
          newSceneVariant,
          ...sceneLayersVariants.slice(activeSceneVariantIndex + 1),
        ],
      });
    },
    togglePerspectiveFullscreen: () =>
      set({ perspectiveFullscreen: !get().perspectiveFullscreen }),
    setCurrentProjectId: (projectId) => set({ currentProjectId: projectId }),
  })),
);

export const useInspirationEditorStore = createSelectorHooks(
  store,
) as typeof store & ZustandHookSelectors<State & Actions>;
