import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { CountryAdminInspirationCard } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/CountryAdminInspirationCard';
import { DraggableInspirationCards } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/DraggableInspirationCards';
import { InspirationPreviewModal } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/InspirationPreviewModal';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { Scene } from '$/services/usecases/scenes';
import { adminScenesQuery } from '$/services/usecases/scenes/queries';

export const CountryAdminInspirationList = () => {
  const [previewScene, setPreviewScene] = useState<Scene | null>(null);

  const scenes = useQuery(adminScenesQuery);
  const countries = useQuery(brandCountriesQuery);
  const user = useAuthenticationStore.useUser();

  const countryToUpdate = countries?.data?.response.payload.find(
    (country) => country.id === user?.managedCountries[0],
  );

  if (!scenes.data || !countryToUpdate) {
    return null;
  }

  const allScenes = Object.values(scenes.data ?? {}).flat();

  const activeScenes = countryToUpdate.inspirationIds.map(
    (id) => allScenes.find((scene) => scene.id === id)!,
  );

  const inactiveScenes = allScenes.filter(
    (scene) => !countryToUpdate.inspirationIds.includes(scene.id),
  );

  return (
    <Stack gap='4' minW='800px'>
      <DraggableInspirationCards
        countryToUpdate={countryToUpdate}
        openModal={setPreviewScene}
        scenes={activeScenes}
      />

      <Stack gap='4'>
        {inactiveScenes.map((scene, i) => (
          <CountryAdminInspirationCard
            key={scene.id}
            scene={scene}
            countryToUpdate={countryToUpdate}
            index={i + activeScenes.length}
            openModal={setPreviewScene}
          />
        ))}
      </Stack>

      <InspirationPreviewModal
        isOpen={!!previewScene}
        scene={previewScene}
        onClose={() => setPreviewScene(null)}
      />
    </Stack>
  );
};
