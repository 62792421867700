import { Box, BoxProps } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
  id: string;
}

export const SortableItem: FC<Props> = ({ id, children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <Box
      ref={setNodeRef}
      zIndex={isDragging ? 2 : 0}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
    >
      {children}
    </Box>
  );
};
