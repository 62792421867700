import {
  Button,
  FormLabel,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  TrackedButton,
  TrackedButtonProps,
} from '$/components/common/Button/TrackedButton';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useTrackInteraction } from '$/hooks/useTrackInteraction';
import { trackContentImpression } from '$/logger';
import { GlobalFavoritePublishModalContent } from '$/pages/DashboardPages/pages/Favorite/components/GlobalFavoritePublishModalContent';
import { useFavoriteModal } from '$/pages/DashboardPages/pages/Favorite/hooks/useFavoriteModal';
import { setGlobalFavoriteFolderState } from '$/services/usecases/favorites';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';
import { useMaterialContextMenuStore } from '$/stores/useMaterialContextMenuStore';
import { PiwikContentPiece } from '$/utils/piwikUtils';

type ModalInfo = {
  header: string;
  body?: ReactNode;
  footer?: ReactNode;
};

export const FavoriteModal = () => {
  const { t } = useTranslation();

  const {
    isOpen,
    onClose,
    modalMode,
    itemIdentifier,
    itemType,
    itemName,
    menuLayer,
  } = useFavoriteModal();
  const deleteFolder = useFavoriteStore.useRemoveFolder();
  const removeFavorite = useFavoriteStore.useRemoveFavorite();
  const renameFolder = useFavoriteStore.useRenameFolder();
  const addFavoriteToFolder = useFavoriteStore.useAddFavoriteToFolder();
  const addFolder = useFavoriteStore.useAddFolder();
  const folders = useFavoriteStore.useFavoriteFolders();
  const favorites = useFavoriteStore.useFavorites();
  const setActiveMaterialContextMenuId =
    useMaterialContextMenuStore.useSetActiveMaterial();
  const getMemorizedCheckedItems =
    useMaterialContextMenuStore.useGetMemorizedCheckedItems();
  const memorizeCheckedItems =
    useMaterialContextMenuStore.useMemorizeCheckedItems();

  const [nameInputValue, setNameInputValue] = useState('');
  const [fileData, setFileData] = useState<File | null>(null);

  const onChangeGlobalFavoriteFolder = async (
    folderId: string,
    isGlobal: boolean,
  ) => {
    await setGlobalFavoriteFolderState(folderId, isGlobal, fileData);
    await useFavoriteStore.getState().getFavorites(true);
  };

  const getCurrentContentPiece = useCallback(():
    | PiwikContentPiece
    | undefined => {
    switch (modalMode) {
      case 'deleteFavorite':
        return 'ManageFavorite';
      case 'createFolder':
        return 'NewFolder';
    }
  }, [modalMode]);

  useEffect(() => {
    if (isOpen) {
      const contentPiece = getCurrentContentPiece();

      if (!contentPiece) {
        return;
      }

      trackContentImpression('Favorite', contentPiece, 'Open');
    }
  }, [isOpen, modalMode, getCurrentContentPiece]);

  const trackCloseButton = useTrackInteraction(
    'Favorite',
    getCurrentContentPiece(),
    'Close',
  );

  const buildFooter = (
    primaryButtonText: string,
    primaryAction: () => void | Promise<void>,
    options?: {
      primaryButtonCustomProps?: TrackedButtonProps;
      customSecondaryButton?: ReactNode;
    },
  ) => (
    <>
      {options?.customSecondaryButton ?? (
        <Button
          px='0'
          fontSize='sm'
          onClick={() => {
            setNameInputValue('');
            onClose();
          }}
          variant='text'
        >
          {t('favorites.cancelButton')}
        </Button>
      )}
      <TrackedButton
        fontSize='sm'
        {...options?.primaryButtonCustomProps}
        onClick={async () => {
          await primaryAction();
          onClose();
        }}
      >
        {primaryButtonText}
      </TrackedButton>
    </>
  );

  useEffect(() => {
    const initialInputValue =
      folders.find((folder) => folder.id === itemIdentifier)?.name ?? '';

    setNameInputValue(initialInputValue);
    setFileData(null);
  }, [folders, itemIdentifier]);

  const { body, header, footer }: ModalInfo = (() => {
    switch (modalMode) {
      case 'deleteFavorite': {
        const favorite = favorites.find(
          (favorite) => favorite.materialId === itemIdentifier,
        );

        return {
          header: t('favorites.removeFavoriteHeader'),
          body: (
            <>
              <Trans
                i18nKey='favorites.removeFavoriteInformation'
                values={{ materialName: itemName }}
              />
              <UnorderedList mt='4' fontSize='sm'>
                {favorite?.parentFolderIds.includes(DEFAULTFOLDERNAME) && (
                  <ListItem>{t('favorites.generalStorage')}</ListItem>
                )}
                {folders
                  .filter((folder) =>
                    favorite?.parentFolderIds.includes(folder.id),
                  )
                  .map((folder) => (
                    <ListItem key={folder.id}>{folder.name}</ListItem>
                  ))}
              </UnorderedList>
            </>
          ),
          footer: buildFooter(
            t('favorites.removeFavoriteHeader'),
            () => removeFavorite(itemIdentifier),
            {
              primaryButtonCustomProps: {
                variant: 'danger',
                contentName: 'Favorite',
                contentPiece: 'ManageFavorite',
                contentTarget: 'Delete',
              },
              customSecondaryButton: (
                <TrackedButton
                  p='0'
                  fontSize='sm'
                  onClick={() => {
                    trackContentImpression('Favorite', 'EditFolder', 'Open');
                    setActiveMaterialContextMenuId(
                      itemIdentifier,
                      itemType,
                      menuLayer,
                    );
                    onClose();
                  }}
                  variant='text'
                  contentName='Favorite'
                  contentPiece='ManageFavorite'
                  contentTarget='EditFolder'
                >
                  {t('favorites.changeFolder')}
                </TrackedButton>
              ),
            },
          ),
        };
      }
      case 'renameFolder':
        return {
          header: t('favorites.renameButton'),
          body: (
            <>
              <FormLabel fontSize='sm'>
                {t('favorites.insertNameLabel')}
              </FormLabel>
              <Input
                onChange={(event) => setNameInputValue(event.target.value)}
                value={nameInputValue}
              />
            </>
          ),
          footer: buildFooter(
            t('favorites.saveButton'),
            () => renameFolder(itemIdentifier, nameInputValue),
            {
              primaryButtonCustomProps: {
                isDisabled: nameInputValue.length === 0,
              },
            },
          ),
        };
      case 'createFolder':
        return {
          header: t('favorites.newFolder'),
          body: (
            <>
              <FormLabel fontSize='sm'>
                {t('favorites.insertNameLabel')}
              </FormLabel>
              <TrackedSection
                contentInteraction='ClickButton'
                contentName='Favorite'
                contentPiece='NewFolder'
                contentTarget='InsertName'
              >
                <Input
                  onChange={(event) => setNameInputValue(event.target.value)}
                  value={nameInputValue}
                />
              </TrackedSection>
            </>
          ),
          footer: buildFooter(
            t('favorites.saveButton'),
            async () => {
              const newFolderId = await addFolder(nameInputValue);
              if (itemIdentifier != null && newFolderId != null) {
                await addFavoriteToFolder(itemIdentifier, newFolderId);
                setActiveMaterialContextMenuId(
                  itemIdentifier,
                  itemType,
                  menuLayer,
                );
                const memorizedCheckedItems = getMemorizedCheckedItems();

                if (memorizedCheckedItems) {
                  memorizeCheckedItems([...memorizedCheckedItems, newFolderId]);
                }
              }
            },
            {
              primaryButtonCustomProps: {
                isDisabled: nameInputValue.length === 0,
                contentName: 'Favorite',
                contentPiece: 'NewFolder',
                contentTarget: 'Submit',
              },
              customSecondaryButton: (
                <TrackedButton
                  px='0'
                  fontSize='sm'
                  onClick={() => {
                    setNameInputValue('');
                    onClose();
                  }}
                  variant='text'
                  contentName='Favorite'
                  contentPiece='NewFolder'
                  contentTarget='Cancel'
                >
                  {t('favorites.cancelButton')}
                </TrackedButton>
              ),
            },
          ),
        };
      case 'deleteFolder':
        return {
          header: t('favorites.deleteFolderHeader'),
          body: <Text>{t('favorites.deleteFolderInformation')}</Text>,
          footer: buildFooter(
            t('favorites.deleteButton'),
            () => deleteFolder(itemIdentifier),
            { primaryButtonCustomProps: { variant: 'danger' } },
          ),
        };
      case 'publishFolder':
        return {
          header: t('favorites.publishModal.header'),
          body: (
            <GlobalFavoritePublishModalContent
              itemIdentifier={itemIdentifier}
              file={fileData}
              onFileSet={setFileData}
            />
          ),
          footer: buildFooter(t('favorites.publishModal.publishButton'), () =>
            onChangeGlobalFavoriteFolder(itemIdentifier, true),
          ),
        };
      case 'hideFolder':
        return {
          header: t('favorites.hideModal.header'),
          body: (
            <Text whiteSpace='pre-line'>
              <Trans
                i18nKey='favorites.hideModal.description'
                values={{
                  folderName: folders.find(
                    (folder) => folder.id === itemIdentifier,
                  )?.name,
                }}
              />
            </Text>
          ),
          footer: buildFooter(
            t('favorites.hideModal.hideButton'),
            () => onChangeGlobalFavoriteFolder(itemIdentifier, false),
            { primaryButtonCustomProps: { variant: 'danger' } },
          ),
        };
    }
  })();

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        setNameInputValue('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent px='6' py='8' bg='background'>
        <ModalHeader>
          <Text>{header}</Text>
          <ModalCloseButton mt='9' mr='9' onClick={trackCloseButton} />
        </ModalHeader>
        <ModalBody fontSize='sm'>{body}</ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='full'>
            {footer}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
