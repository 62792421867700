import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { EditBrandCountryForm } from '$/pages/AdminPages/pages/CountriesPage/components/EditBrandCountryModal/components/EditBrandCountryForm';
import { useBrandCountryStore } from '$/pages/AdminPages/pages/CountriesPage/stores/useBrandCountryStore';

export const EditBrandCountryModal = () => {
  const { t } = useTranslation();

  const isOpen = useBrandCountryStore.useIsEditModalOpen();
  const onClose = useBrandCountryStore.useOnEditModalClose();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' minW='600px' p='10'>
        <ModalHeader p='0'>
          <ModalCloseButton top='10' right='10' />

          <Text pt='1' fontSize='h7' lineHeight='10'>
            {t('admin.countries.editCountryBrand.editCountryBrand')}
          </Text>
        </ModalHeader>

        <ModalBody p='0'>
          <EditBrandCountryForm onCloseModal={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
