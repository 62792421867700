import { IconButton } from '@chakra-ui/react';

import { Icon } from '$/components/common/Icon';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const FullscreenButton = () => {
  const setIsFullscreen = useEditorStore.useSetIsFullscreen();
  const { selectAction } = useEditorActions();

  const handleClick = () => {
    setIsFullscreen(false);
    selectAction(ModuleSendSelectAction.ZoomInnerfit);
  };

  return (
    <IconButton
      pos='absolute'
      top='10'
      right='10'
      transition='opacity 0.3s'
      aria-label=''
      icon={<Icon icon='zoom_in_map' />}
      onClick={handleClick}
      variant='secondary'
    />
  );
};
