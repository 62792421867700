import { VStack } from '@chakra-ui/react';
import { DragEndEvent } from '@dnd-kit/core';
import { useState, FC, useEffect } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { DnDContext } from '$/components/core/DragAndDrop/DnDContext';
import { SortableContext } from '$/components/core/DragAndDrop/SortableContext';
import { SortableItem } from '$/components/core/DragAndDrop/SortableItem';
import { CountryAdminInspirationCard } from '$/pages/AdminPages/pages/InspirationsPage/pages/CountryAdminInspirationPage/components/CountryAdminInspirationCard';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { useUpdateBrandCountry } from '$/services/usecases/admin/mutations/useUpdateBrandCountry';
import { Scene } from '$/services/usecases/scenes';
import { reorderArray } from '$/utils/arrayUtils';

interface Props {
  scenes: Scene[];
  countryToUpdate: BrandCountry;
  openModal: (scene: Scene) => void;
}

export const DraggableInspirationCards: FC<Props> = ({
  scenes,
  countryToUpdate,
  openModal,
}) => {
  const user = useAuthenticationStore.useUser();
  const { mutate, isPending } = useUpdateBrandCountry();
  const [orderedScenes, setOrderedScenes] = useState<Scene[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (scenes.length) setOrderedScenes(scenes);
  }, [scenes]);

  const onDragEnd = (result: DragEndEvent) => {
    setIsDragging(false);
    if (result.over == null) {
      return;
    }

    const startIndex = orderedScenes.findIndex(
      (scene) => scene.id === result.active.id,
    );
    const destinationIndex = orderedScenes.findIndex(
      (scene) => scene.id === result.over!.id,
    );

    if (startIndex === -1 || destinationIndex === -1) {
      return;
    }

    const items = reorderArray(orderedScenes, startIndex, destinationIndex);

    if (!user || !user.managedCountries.length) return;

    mutate({
      brandCountry: { inspirationIds: items.map((item) => item.id) },
      id: user?.managedCountries[0],
    });

    setOrderedScenes(items);
  };

  return (
    <VStack
      alignItems='stretch'
      gap='4'
      w='full'
      border='2px solid'
      borderColor={isDragging ? 'primaryButton.background' : 'transparent'}
      borderRadius='8px'
    >
      <DnDContext
        onDragEnd={onDragEnd}
        onDragStart={() => setIsDragging(true)}
        modifiers={['limitToVerticalAxis', 'restrictToParentElement']}
      >
        <SortableContext
          itemIds={orderedScenes.map((scene) => scene.id)}
          sortingStrategy='vertical'
          isDropDisabled={isPending}
        >
          {orderedScenes.map((scene, i) => (
            <SortableItem key={scene.id} id={scene.id}>
              <CountryAdminInspirationCard
                scene={scene}
                countryToUpdate={countryToUpdate}
                index={i}
                openModal={openModal}
              />
            </SortableItem>
          ))}
        </SortableContext>
      </DnDContext>
    </VStack>
  );
};
