import { backendEndpoints } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { GeneralBackendResponseObjectSchema } from '$/services/mapper/authentication';
import { validateToken } from '$/services/usecases/authentication';
import { User } from '$/services/usecases/authentication/mapper/genericCloudResponse';
import { UpdateDataResponseSchema } from '$/services/usecases/profile/mapper/updateData';

export type ProfileData = Pick<User, 'firstName' | 'lastName' | 'email'>;

export const uploadProfilePicture = async (image: File) => {
  const data = new FormData();
  data.append('file', image);

  await validateToken();

  return await fetcher(backendEndpoints.UPLOAD_PROFILE_PICTURE, {
    method: 'PATCH',
    body: data,
    includeCredentials: true,
    isFormData: true,
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const setName = async (firstname: string, lastname: string) => {
  await validateToken();
  const response = await fetcher(backendEndpoints.CHANGE_NAME_URL, {
    body: {
      firstname,
      lastname,
    },
    method: 'PATCH',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    includeCredentials: true,
  });
  await validateToken(true);
  return response;
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  await validateToken();
  return await fetcher(backendEndpoints.CHANGE_PASSWORD_URL, {
    body: {
      oldPassword,
      password: newPassword,
    },
    method: 'PATCH',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    includeCredentials: true,
  });
};

export const updateEmail = async (email: string, otp?: string) => {
  await validateToken();
  return await fetcher(backendEndpoints.CHANGE_EMAIL_URL, {
    body: {
      email,
      otp,
    },
    method: 'PATCH',
    parser: (data) => UpdateDataResponseSchema.parse(data),
    includeCredentials: true,
  });
};

export const updateCountry = async (country: string) => {
  await validateToken();
  return await fetcher(backendEndpoints.CHANGE_COUNTRY_URL, {
    body: { country },
    method: 'PATCH',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    includeCredentials: true,
  });
};

export const deleteAccount = async () => {
  await validateToken();
  return await fetcher(backendEndpoints.DELETE_ACCOUNT, {
    method: 'DELETE',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    includeCredentials: true,
  });
};
