import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DeleteCountryAdminForm } from '$/pages/AdminPages/pages/UserPage/components/EditCountryAdminModal/components/DeleteCountryAdminForm';
import { EditCountryAdminForm } from '$/pages/AdminPages/pages/UserPage/components/EditCountryAdminModal/components/EditCountryAdminForm';
import { useAdminUserStore } from '$/pages/AdminPages/pages/UserPage/stores/useAdminUserStore';

export const EditCountryAdminModal = () => {
  const { t } = useTranslation();

  const modalType = useAdminUserStore.useEditModalType();
  const isOpen = useAdminUserStore.useIsEditModalOpen();
  const onClose = useAdminUserStore.useOnEditModalClose();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' minW='500px' p='10'>
        <ModalHeader p='0'>
          <ModalCloseButton top='10' right='10' />

          <Text pt='1' fontSize='h7'>
            {modalType === 'update_admin'
              ? t('admin.userManagement.editUser.editUser')
              : t('admin.userManagement.editUser.delete')}
          </Text>
        </ModalHeader>

        <ModalBody p='0'>
          {modalType === 'update_admin' && <EditCountryAdminForm />}
          {modalType === 'delete_admin' && <DeleteCountryAdminForm />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
