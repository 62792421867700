import { Input, FormLabel, Flex, Box, Button } from '@chakra-ui/react';
import { Dispatch, SetStateAction, FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
  maxSizeInMb?: number;
}

export const FileInput: FC<Props> = ({
  selectedFile,
  maxSizeInMb,
  setSelectedFile,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (file: File | undefined) => {
    if (!file) return;

    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    const extension = file.name.split('.').pop()?.toLowerCase();

    if (!extension || !allowedExtensions.includes(extension)) {
      // TODO: errorhandling
      return;
    }

    if (maxSizeInMb && file.size > maxSizeInMb * 1024 * 1024) {
      // TODO: errorhandling
      return;
    }

    setSelectedFile(file);
  };

  return (
    <FormLabel w='full'>
      <Input
        ref={inputRef}
        display='none'
        w='full'
        p='0'
        accept='.jpg,.jpeg,.png'
        id='file_input'
        onChange={(e) => handleFileChange(e.target.files?.[0])}
        placeholder={t('editor.choose_picture')}
        type='file'
      />
      <Flex
        align='center'
        justify='space-between'
        w='full'
        p='1'
        pl='4'
        bg='bodyBackground'
        border={selectedFile == null ? '1px solid' : '2px solid'}
        borderColor={selectedFile == null ? 'border' : 'success.950'}
        borderRadius='4px'
      >
        <Box
          as='span'
          overflowX='auto'
          mr='2'
          color={selectedFile?.name ? 'text' : 'placeholderText'}
        >
          {selectedFile?.name ?? t('editor.choose_picture')}
        </Box>
        {selectedFile == null ? (
          <Button
            h='8'
            minH='0'
            fontSize='xs'
            fontWeight='normal'
            onClick={() => inputRef.current?.click()}
          >
            {t('editor.uploadIndicator')}
          </Button>
        ) : (
          <Button
            minW='4.625rem'
            h='8'
            minH='0'
            bg='success.950'
            _hover={{ bg: 'success.950' }}
            _active={{ bg: 'success.950' }}
            onClick={() => inputRef.current?.click()}
          >
            <Icon icon='check_icon' width='14px' />
          </Button>
        )}
      </Flex>
    </FormLabel>
  );
};
