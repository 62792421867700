import {
  Box,
  Button,
  Center,
  HStack,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { EditInspirationModal } from '$/pages/AdminPages/pages/InspirationsPage/pages/SuperAdminInspirationPage/components/EditInspirationModal';
import { InspirationItem } from '$/pages/AdminPages/pages/InspirationsPage/pages/SuperAdminInspirationPage/components/InspirationItem';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';
import { Scene } from '$/services/usecases/scenes';
import { adminScenesQuery } from '$/services/usecases/scenes/queries';

export const SuperAdminInspirationPage = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedScene, setSelectedScene] = useState<Scene | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const brandCountries = useQuery(brandCountriesQuery);
  const scenes = useQuery(adminScenesQuery);

  const onEditScene = (scene: Scene) => {
    setSelectedScene(scene);
    onOpen();
  };

  const onCloseModal = () => {
    onClose();
    setSelectedScene(null);
  };

  if (brandCountries.isLoading || scenes.isLoading) {
    return (
      <VStack alignItems='start' h='full' p='8'>
        <StyledScrollWrapper>
          <GeneralAdminHeader
            title={t('admin.inspirations.title')}
            description={t('admin.inspirations.description')}
          />
          <Center>
            <Spinner />
          </Center>
        </StyledScrollWrapper>
      </VStack>
    );
  }

  const filteredScenes =
    selectedCategory != null
      ? (scenes.data?.[selectedCategory] ?? [])
      : Object.values(scenes.data ?? {}).flat();

  return (
    <Stack alignItems='start' h='full' p='8'>
      <StyledScrollWrapper>
        <GeneralAdminHeader
          title={t('admin.inspirations.title')}
          description={t('admin.inspirations.description')}
        />
        <Menu>
          {({ isOpen: isMenuOpen }) => (
            <>
              <MenuButton
                as={Button}
                h='auto'
                minH='0'
                my='4'
                p='0'
                color='lighterText'
                fontSize='sm'
                rightIcon={
                  <Icon
                    icon='chevron_down'
                    boxSize='3'
                    transform={`rotate(${isMenuOpen ? '180deg' : '0deg'})`}
                  />
                }
                variant='ghost'
              >
                {t('admin.inspirations.categoryFilter')}
              </MenuButton>
              <Portal>
                <MenuList>
                  {Object.keys(scenes.data ?? {}).map((category) => (
                    <MenuItem
                      key={category}
                      fontSize='sm'
                      onClick={() => setSelectedCategory(category)}
                    >
                      {t(`dashboard.inspiration.scenes.${category}`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
        {selectedCategory != null && (
          <HStack mb='4'>
            <Text fontSize='sm'>
              {t('dashboard.collection.resultsAmount.withFilter', {
                amount: filteredScenes.length,
              })}
            </Text>
            <Button
              fontSize='sm'
              fontWeight='normal'
              bg='secondaryBackground'
              borderColor='borderHover'
              borderRadius='full'
              onClick={() => setSelectedCategory(null)}
              rightIcon={<Icon icon='close' boxSize='2' />}
              variant='secondary'
            >
              {t(`dashboard.inspiration.scenes.${selectedCategory}`)}
            </Button>
          </HStack>
        )}
        <Box overflowX='auto'>
          <List w='full' minW='800px' variant='unstyled'>
            {filteredScenes.map((scene) => (
              <InspirationItem
                scene={scene}
                key={scene.id}
                onEdit={onEditScene}
                brandCountries={
                  brandCountries.data?.response.payload.filter((brandCountry) =>
                    brandCountry.inspirationIds.includes(scene.id),
                  ) ?? []
                }
              />
            ))}
          </List>
        </Box>
        <EditInspirationModal
          brandCountries={brandCountries.data?.response.payload ?? []}
          isOpen={isOpen}
          onClose={onCloseModal}
          scene={selectedScene}
        />
      </StyledScrollWrapper>
    </Stack>
  );
};
