import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { InfoTooltip } from '$/components/common/InfoTooltip';
import { FileInput } from '$/pages/EditorPage/components/LoadModal/FileInput';
import { removeSpecialCharacters } from '$/utils/stringUtils';

interface Props {
  onUploadImage: (
    name: string,
    image: File,
    onClose: () => void,
  ) => Promise<void>;
  maxSizeInMb?: number;
}

export const LoadModal: FC<Props> = ({ maxSizeInMb, onUploadImage }) => {
  const { t } = useTranslation();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const goBack = () => {
    onClose();
    void navigate({ from: '.', to: '../' });
  };

  const uploadDisabled = selectedFile == null || projectName.length < 2;

  const handleUpload = async () => {
    if (uploadDisabled) return;

    setLoading(true);
    await onUploadImage(projectName, selectedFile, onClose).finally(() =>
      setLoading(false),
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent borderRadius='12px'>
        <ModalBody w='32rem' h='46rem' bg='background' borderRadius='12px'>
          <VStack
            justifyContent='left'
            gap='6'
            h='full'
            m='4'
            fontSize='sm'
            bg='background'
          >
            <Heading
              as='h3'
              w='full'
              my='4'
              fontSize='2xl'
              fontWeight='bold'
              textAlign='left'
            >
              {t('editor.new_project')}
            </Heading>

            <Box>
              <Text display='inline'>{t('editor.modal_text')}</Text>
              <Box alignItems='baseline' display='inline-flex'>
                <InfoTooltip
                  info={
                    <Box fontSize='sm' lineHeight='short'>
                      <Text fontWeight='bold'>
                        {t('dashboard.authentication.info.headline')}
                      </Text>
                      <Text>{t('dashboard.authentication.info.noteText')}</Text>
                    </Box>
                  }
                  maxW='40rem'
                  border='1px solid'
                  borderColor='border'
                  icon={
                    <Icon
                      icon='info'
                      color='text'
                      cursor='help'
                      boxSize='12px'
                      mb='1'
                      ml='1'
                    />
                  }
                />
              </Box>
            </Box>

            <Box w='full'>
              <Text w='full' mb='2' fontSize='sm'>
                {t('editor.projectName')}
              </Text>
              <Input
                w='full'
                px='4'
                id='file_input'
                onChange={(e) =>
                  setProjectName(removeSpecialCharacters(e.target.value))
                }
                placeholder={t('dashboard.authentication.defaultPlaceholder', {
                  field: t('editor.projectName'),
                })}
                type='text'
                value={projectName}
              />
            </Box>
            <Box w='full'>
              <HStack justifyContent='space-between' w='full'>
                <Box w='full' mb='2'>
                  {t('editor.upload_picture')}
                </Box>
              </HStack>

              <FileInput
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                maxSizeInMb={maxSizeInMb}
              />
            </Box>

            {t('editor.new_project')}
            <HStack justifyContent='space-between' w='full' mt='4'>
              <Button onClick={goBack} variant='text'>
                {t('editor.cancel')}
              </Button>

              <Button
                px='16'
                bg={uploadDisabled ? 'border' : 'editor.btnShareBg'}
                isDisabled={uploadDisabled}
                isLoading={loading}
                onClick={handleUpload}
              >
                {t('editor.upload')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
