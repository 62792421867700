import { Box, Button, HStack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { AddLanguageModal } from '$/pages/AdminPages/pages/LanguagesPage/components/AddLanguageModal';

export const LanguagesPageHeader = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const role = useAuthenticationStore.useUserRole();

  return (
    <HStack alignItems='start' justify='space-between'>
      <GeneralAdminHeader
        title={t('admin.languages.manageLanguages')}
        description={t(
          role === 'superAdmin'
            ? 'admin.languages.manageLanguagesDescription'
            : 'admin.languages.manageLanguagesDescriptionCountryAdmin',
        )}
      />
      {role === 'superAdmin' && (
        <>
          <Button
            pl={{ base: '2', md: '4' }}
            fontSize='sm'
            onClick={onOpen}
            rightIcon={<Icon icon='add_sign' w='12px' />}
          >
            <Box as='span' display={{ base: 'none', md: 'block' }}>
              {t('admin.languages.addLanguage.addLanguage')}
            </Box>
          </Button>
          <AddLanguageModal isOpen={isOpen} onClose={onClose} />
        </>
      )}
    </HStack>
  );
};
