import { Box, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ColorInput } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/ColorInput';
import { ColorPicker } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/ColorPicker';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

type Color = {
  r: number;
  g: number;
  b: number;
  a: number;
};

const valueToString = (value: Color) => {
  return `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
};

export const GradientPicker = () => {
  const [colors, setColors] = useState<Color[]>([
    { r: 0, g: 0, b: 0, a: 1 },
    { r: 255, g: 255, b: 255, a: 1 },
  ]);
  const [activeColor, setActiveColor] = useState(0);
  const [gradientType] = useState<'linear' | 'radial'>('linear');
  const { setRetouchGradientColor } = useEditorActions();

  const onChangeColor = (newColor: Color) => {
    setColors((currentColors) => {
      const newColors = [...currentColors];
      newColors[activeColor] = newColor;
      return newColors;
    });
  };

  useEffect(() => {
    let timeoutId: number | null = null;
    if (timeoutId != null) clearTimeout(timeoutId);

    timeoutId = window.setTimeout(
      () =>
        setRetouchGradientColor(
          activeColor,
          colors[activeColor].r,
          colors[activeColor].g,
          colors[activeColor].b,
          colors[activeColor].a,
        ),
      100,
    );

    return () => {
      if (timeoutId != null) clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeColor, colors]);

  return (
    <VStack>
      <Box
        pos='relative'
        w='full'
        h='4'
        bgGradient={`${gradientType}(${gradientType === 'linear' ? 'to-r,' : ''}${valueToString(colors[0])}, ${valueToString(colors[1])})`}
        border='1px solid'
        borderColor='border'
        borderRadius='full'
        boxSizing='border-box'
      >
        <RadioGroup
          defaultValue='0'
          onChange={(val) => setActiveColor(Number.parseInt(val))}
          value={activeColor.toString()}
        >
          <HStack pos='absolute' justify='space-between' w='full' mt='-2px'>
            {colors.map((color, index) => (
              <Radio
                key={index}
                bg={valueToString(color)}
                borderColor={
                  activeColor === index
                    ? 'primaryButton.activeBackground'
                    : 'text'
                }
                _checked={{ before: {} }}
                value={index.toString()}
                variant='colorSlider'
              />
            ))}
          </HStack>
        </RadioGroup>
      </Box>
      <ColorPicker color={colors[activeColor]} setColor={onChangeColor} />
      <ColorInput color={colors[activeColor]} setColor={onChangeColor} />
    </VStack>
  );
};
