import { useEffect } from 'react';

export const useCookieDeclaration = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'CookieDeclaration';
    script.src =
      'https://consent.cookiebot.com/1a68e49c-76fc-4646-827f-10158996e910/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    const container = document.getElementById('cookie-declaration');

    container?.appendChild(script);

    return () => {
      container?.removeChild(script);
    };
  }, []);
};
