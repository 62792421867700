import { Box, BoxProps } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { FC, ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
  id: string;
  isDisabled?: boolean;
}

export const Droppable: FC<Props> = ({
  children,
  id,
  isDisabled,
  ...props
}) => {
  const { setNodeRef } = useDroppable({
    id,
    disabled: !!isDisabled,
  });

  return (
    <Box {...props} ref={setNodeRef}>
      {children}
    </Box>
  );
};
