import {
  SortableContext as DndKitSortableContext,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { FC, ReactNode } from 'react';

type SortingStrategy = 'vertical' | 'horizontal' | 'grid';

const getSortingStrategy = (strategy: SortingStrategy) => {
  switch (strategy) {
    case 'vertical':
      return verticalListSortingStrategy;
    case 'horizontal':
      return horizontalListSortingStrategy;
    case 'grid':
      return rectSortingStrategy;
  }
};

interface Props {
  children: ReactNode;
  itemIds: string[];
  sortingStrategy?: SortingStrategy;
  isDropDisabled?: boolean;
  id?: string;
}

export const SortableContext: FC<Props> = ({
  children,
  itemIds,
  sortingStrategy = 'grid',
  isDropDisabled = false,
  id,
}) => {
  return (
    <DndKitSortableContext
      items={itemIds}
      strategy={getSortingStrategy(sortingStrategy)}
      disabled={isDropDisabled}
      id={id}
    >
      {children}
    </DndKitSortableContext>
  );
};
