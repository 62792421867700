import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useEffect, useState, ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ActionBarTooltip } from '$/components/core/Editor/ActionBar/components/ActionBarTooltip';

const maxZoomLevel = 800;
const minZoomLevel = 10;

interface Props {
  zoomLevel: number;
  setZoomLevel: (zoomLevel: number) => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onResetZoom: () => void;
  isSmallMode?: boolean;
}

export const EditorZoomAction: FC<Props> = ({
  onResetZoom,
  onZoomIn,
  onZoomOut,
  setZoomLevel,
  zoomLevel,
  isSmallMode = false,
}) => {
  const { t } = useTranslation();
  const [zoomInput, setZoomInput] = useState<number>(
    Math.round(zoomLevel * 100),
  );

  useEffect(() => {
    setZoomInput(Math.round(zoomLevel * 100));
  }, [zoomLevel]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newZoomLevel = Number(event.target.value);
    setZoomInput(Math.min(maxZoomLevel, newZoomLevel));
  };

  const handleInputSubmit = () => {
    const boundedZoomLevel = Math.max(
      minZoomLevel,
      Math.min(zoomInput, maxZoomLevel),
    );
    setZoomLevel(boundedZoomLevel / 100);
  };

  const fullBar = (
    <HStack gap='1' h='full'>
      <ActionBarTooltip label={t('editor.zoomOut.tooltip')}>
        <IconButton
          w='8'
          minW='0'
          aria-label={t('editor.zoom_out')}
          icon={<Icon icon='zoom_out' />}
          onClick={onZoomOut}
          variant='text'
        />
      </ActionBarTooltip>
      <InputGroup w={zoomInput < 1000 ? '3.7rem' : '4.2rem'} fontWeight='bold'>
        <Input
          px='2'
          pr='2'
          fontSize='sm'
          fontWeight='bold'
          bg='transparent'
          borderColor='transparent'
          _focus={{
            borderColor: 'border',
            boxShadow: 'none',
          }}
          onChange={handleInputChange}
          onKeyDown={(event) => {
            event.stopPropagation();

            if (event.key === 'Enter') {
              handleInputSubmit();
            }
          }}
          type='number'
          value={zoomInput}
        />
        <InputRightElement w='auto' mr='2' fontSize='sm' bg='transparent'>
          %
        </InputRightElement>
      </InputGroup>

      <ActionBarTooltip label={t('editor.zoomIn.tooltip')}>
        <IconButton
          w='8'
          minW='0'
          aria-label={t('editor.zoom_in')}
          icon={<Icon icon='zoom_in' />}
          onClick={onZoomIn}
          variant='text'
        />
      </ActionBarTooltip>
      <Box w='2px' h='20px' mx='2' bg='bodyBackground' borderRadius='2px' />
      <ActionBarTooltip label={t('editor.zoomReset.tooltip')}>
        <IconButton
          w='8'
          minW='0'
          aria-label={t('editor.zoom_in')}
          icon={<Icon icon='zoom_in_map' boxSize='16px' />}
          onClick={onResetZoom}
          variant='text'
        />
      </ActionBarTooltip>
    </HStack>
  );

  if (isSmallMode) {
    return (
      <Popover placement='bottom-end'>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Button
                px='0'
                color='text'
                fontSize='sm'
                rightIcon={
                  <Icon
                    icon='chevron_down'
                    transform={isOpen ? 'rotate(180deg)' : ''}
                    transition='transform 0.2s ease'
                    w='12px'
                  />
                }
                variant='text'
              >
                {zoomInput}%
              </Button>
            </PopoverTrigger>
            <PopoverContent w='full' px='4' py='1' bg='background'>
              {fullBar}
            </PopoverContent>
          </>
        )}
      </Popover>
    );
  }

  return fullBar;
};
