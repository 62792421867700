import { Box, BoxProps } from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { FC, ReactNode } from 'react';

interface Props extends BoxProps {
  children?: ReactNode;
  id: string;
  isDisabled?: boolean;
}

export const Draggable: FC<Props> = ({
  children,
  id,
  isDisabled,
  ...props
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id,
      disabled: !!isDisabled,
    });
  return (
    <Box
      zIndex={isDragging ? 2 : 0}
      {...props}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      transform={
        transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined
      }
    >
      {children}
    </Box>
  );
};
