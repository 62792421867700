import { useQuery } from '@tanstack/react-query';
import { TFunction } from 'i18next';

import { adminLanguagesQuery } from '$/services/usecases/admin/languages';

export const useFilteredLanguageCodes = (
  t: TFunction<'translation', undefined>,
  query: string,
  selectedLanguages: string[],
) => {
  const languages = useQuery(adminLanguagesQuery);

  const languageCodes = languages.data?.response.payload?.map(
    (language) => language.id,
  );

  const initialFiltered = languageCodes?.filter(
    (code) => !selectedLanguages.includes(code),
  );

  if (!query || query === '') {
    return {
      filteredLanguageCodes: initialFiltered,
      unfilteredLanguageCodes: languageCodes,
    };
  }

  if (languageCodes && initialFiltered) {
    const filteredLanguageCodes = initialFiltered.filter(
      (code) =>
        code.toLowerCase().includes(query.toLowerCase()) ||
        t(`languages.${code}`).toLowerCase().includes(query.toLowerCase()),
    );

    return {
      filteredLanguageCodes,
      unfilteredLanguageCodes: languageCodes,
    };
  }

  return {
    filteredLanguageCodes: [],
    unfilteredLanguageCodes: [],
  };
};
