import { Button, HStack, Heading, Stack } from '@chakra-ui/react';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { FilterTags } from '$/components/core/Collection/MaterialPresentation/DataSortingRow/components/FilterTags';
import { DnDContext } from '$/components/core/DragAndDrop/DnDContext';
import { SortableContext } from '$/components/core/DragAndDrop/SortableContext';
import { SortableItem } from '$/components/core/DragAndDrop/SortableItem';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { FavoriteGroup } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteGroup';
import { useGroupedFavorites } from '$/pages/DashboardPages/pages/Favorite/hooks/useGroupedFavorites';
import { MaterialType } from '$/services/mapper/uses';
import { changeFolderOrder } from '$/services/usecases/favorites';
import { Material } from '$/services/usecases/materials';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

export const FavoriteFolderView = () => {
  const { t } = useTranslation();
  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const folders = useFavoriteStore.useFavoriteFolders();
  const globalFavoriteFolders = useFavoriteStore.useGlobalFavoriteFolders();
  const [draggedMaterial, setDraggedMaterial] = useState<Material | null>(null);
  const [materialSorting, setMaterialSorting] = useState<string[]>([]);

  const currentFolder =
    folders.find((folder) => folder.id === activeFolderId) ??
    globalFavoriteFolders.find((folder) => folder.id === activeFolderId);

  useEffect(() => {
    if (currentFolder == null) {
      setMaterialSorting([]);
      return;
    }
    setMaterialSorting(currentFolder.materialOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveFolderId]);

  const {
    colorFavorites,
    floorFavorites,
    wallFavorites,
    facadeFavorites,
    allFavorites,
    isLoading,
  } = useGroupedFavorites(
    activeFolderId!,
    [],
    true,
    undefined,
    materialSorting,
  );

  const editMode = currentFolder?.isGlobalFavoriteFolder
    ? 'none'
    : 'favoriteExtended';

  const onDragStart = (event: DragStartEvent) => {
    const favorite = allFavorites.find(
      (favorite) => favorite.material.uniqueKey === event.active.id,
    );
    setDraggedMaterial(favorite?.material ?? null);
  };

  const onDragEnd = async (event: DragEndEvent) => {
    setDraggedMaterial(null);
    if (event.over?.id !== event.active.id) {
      const materialIds = allFavorites.map(
        (favorite) => favorite.material.uniqueKey,
      );
      const oldIndex = materialIds.indexOf(event.active.id.toString());
      const newIndex = materialIds.indexOf(
        event.over?.id.toString() ?? event.active.id.toString(),
      );

      const sortedIds = arrayMove(materialIds, oldIndex, newIndex);
      setMaterialSorting(sortedIds);
      await changeFolderOrder(activeFolderId!, sortedIds);
      await useFavoriteStore.getState().getFavorites(true);
    }
  };

  const getSortableFavoriteGroup = (
    favorites: FavoriteItem[],
    headline: string,
    materialType: MaterialType,
  ) => (
    <SortableContext
      itemIds={favorites.map((favorite) => favorite.material.uniqueKey)}
      isDropDisabled={!!currentFolder?.isGlobalFavoriteFolder}
    >
      <FavoriteGroup
        headline={headline}
        favorites={favorites}
        isLoading={isLoading}
        materialType={materialType}
        editMode={editMode}
        ItemWrapper={({ favorite, children }) => (
          <SortableItem id={favorite.material.uniqueKey}>
            {children}
          </SortableItem>
        )}
      />
    </SortableContext>
  );

  return (
    <>
      <HStack>
        <Button
          px='0'
          leftIcon={<Icon icon='arrow_right' transform='rotate(180deg)' />}
          onClick={() => setActiveFolderId(null)}
          variant='text'
        >
          {t('general.backToOverview')}
        </Button>
      </HStack>
      <Heading as='h1' mt='2' mb='4' fontSize='3xl'>
        {currentFolder?.name}
      </Heading>

      <FilterTags />

      <Stack gap='6' pt='6'>
        <DnDContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          dragPlaceholder={
            draggedMaterial ? (
              <MaterialCard
                material={draggedMaterial}
                opacity={0.6}
                editMode='none'
              />
            ) : undefined
          }
          modifiers={['restrictToParentElement']}
        >
          {getSortableFavoriteGroup(
            colorFavorites,
            t('dashboard.collection.search.colorTones'),
            'uni',
          )}
          {getSortableFavoriteGroup(
            wallFavorites,
            t('dashboard.collection.search.wallCoverings'),
            'wall',
          )}
          {getSortableFavoriteGroup(
            floorFavorites,
            t('dashboard.collection.search.floorCoverings'),
            'floor',
          )}
          {getSortableFavoriteGroup(
            facadeFavorites,
            t('dashboard.collection.search.facades'),
            'facade',
          )}
        </DnDContext>
        <AuthenticationModal />
      </Stack>
    </>
  );
};
