import { Box, Button, HStack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { AddBrandCountryModal } from '$/pages/AdminPages/pages/CountriesPage/components/AddBrandCountryModal';

export const CountryPageHeader = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HStack alignItems='start' justify='space-between'>
      <GeneralAdminHeader
        title={t('admin.countries.manageCountriesAndBrands')}
        description={t('admin.countries.manageCountriesAndBrandsDescription')}
      />
      <Button
        pl={{ base: '2', md: '4' }}
        fontSize='sm'
        onClick={onOpen}
        rightIcon={<Icon icon='add_sign' w='12px' />}
      >
        <Box as='span' display={{ base: 'none', md: 'block' }}>
          {t('admin.countries.addCountryBrand.addCountryBrand')}
        </Box>
      </Button>
      <AddBrandCountryModal isOpen={isOpen} onClose={onClose} />
    </HStack>
  );
};
