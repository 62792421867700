export interface RotationConverterProps {
  direction: string;
}

export const convertRotation = (direction: string): string => {
  let rotation: string;

  switch (direction) {
    case 'diagonal-right':
      rotation = '45';
      break;
    case 'diagonal-left':
      rotation = '135';
      break;
    case 'horizontal':
      rotation = '90';
      break;
    case 'vertical':
      rotation = '0';
      break;
    default:
      rotation = '0';
  }

  return rotation;
};
