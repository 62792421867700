import { Box, Grid } from '@chakra-ui/react';
import { ComponentType, FC, ReactNode } from 'react';

import { For } from '$/components/common/Flow/For';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialCardEditMode } from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { MaterialType } from '$/services/mapper/uses';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  favorites: FavoriteItem[];
  isLoading: boolean;
  headline: string;
  materialType: MaterialType;
  editMode?: MaterialCardEditMode;
  isDragDisabled?: boolean;
  setMoveMode?: (isPaste: boolean) => void;
  ItemWrapper?: ComponentType<{ favorite: FavoriteItem; children: ReactNode }>;
}

export const FavoriteGroup: FC<Props> = ({
  favorites,
  isLoading,
  headline,
  materialType,
  editMode,
  setMoveMode,
  ItemWrapper = Box,
}) => {
  const setActiveMaterial = useMaterialDetailStore.useSetActive();
  const itemLimit = useMaterialGridMaxItems();
  const { hasPermission } = useAuthorization();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  if (favorites.length === 0 && !isLoading) return;

  if (isLoading)
    return (
      <SectionHeadline headline={headline}>
        <Grid gap='6' templateColumns={materialTemplateColumn()}>
          {repeat(itemLimit).map((i) => (
            <LoadingMaterialCard key={i} />
          ))}
        </Grid>
      </SectionHeadline>
    );

  return (
    <SectionHeadline headline={headline}>
      <Grid gap='6' templateColumns={materialTemplateColumn()}>
        <For each={favorites}>
          {(favorite) => {
            return (
              <ItemWrapper
                favorite={favorite}
                key={favorite.material.uniqueKey}
              >
                <Box key={favorite.material.id}>
                  <MaterialCard
                    material={favorite.material}
                    onMouseDown={(event) =>
                      setMoveMode && setMoveMode(event.ctrlKey || event.metaKey)
                    }
                    onMouseUp={() => setMoveMode && setMoveMode(false)}
                    onClick={() => {
                      if (
                        !hasPermission('Use_Pro_Materials') &&
                        favorite.material.isProMaterial
                      ) {
                        openAuthenticationModal('versionComparison');
                      } else {
                        setActiveMaterial(favorite.material);
                      }
                    }}
                    editMode={editMode}
                    materialType={materialType}
                  />
                </Box>
              </ItemWrapper>
            );
          }}
        </For>
      </Grid>
    </SectionHeadline>
  );
};
