import {
  HStack,
  Button,
  Stack,
  Text,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HookFormDropdownInput,
  HookFormDropdownInputProps,
} from '$/components/core/Form/HookFormDropdownInput';
import {
  HookFormSwitch,
  HookFormSwitchProps,
} from '$/components/core/Form/HookFormSwitch';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { useFilteredBrandCountries } from '$/pages/AdminPages/pages/UserPage/hooks/useFilteredBrandCountries';
import { useAdminUserStore } from '$/pages/AdminPages/pages/UserPage/stores/useAdminUserStore';
import { queryClient } from '$/services/fetcher';
import {
  countryAdminsQuery,
  updateCountryAdmin,
} from '$/services/usecases/admin/countryAdmins';

type AddCountryAdminData = {
  managedCountry: string;
  isActiveAdmin: boolean;
};

const CountryInput = (
  props: HookFormDropdownInputProps<AddCountryAdminData>,
) => <HookFormDropdownInput<AddCountryAdminData> {...props} />;

const ActiveSwitch = (props: HookFormSwitchProps<AddCountryAdminData>) => (
  <HookFormSwitch<AddCountryAdminData> {...props} />
);

export const EditCountryAdminForm = () => {
  const { t } = useTranslation();
  const initialRender = useRef(true);

  const onCloseModal = useAdminUserStore.useOnEditModalClose();
  const activeUser = useAdminUserStore.useActiveEditUser()!;
  const setModalType = useAdminUserStore.useSetEditModalType();

  const formMethods = useForm<AddCountryAdminData>({
    defaultValues: {
      isActiveAdmin: activeUser.isActiveAdmin,
      managedCountry: t(`countries.${activeUser.managedCountries[0]}`),
    },
  });

  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateLoading, setUpdateLoading] = useState(false);

  const query = formMethods.watch('managedCountry');
  const { filteredCountries, unfilteredCountries } =
    useFilteredBrandCountries(query);

  useEffect(() => {
    if (query?.length > 0 && !initialRender.current) onOpen();
  }, [query, onOpen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      initialRender.current = false;
    }, 50);

    return () => clearTimeout(timeout);
  }, []);

  const onSubmit = async (data: AddCountryAdminData) => {
    if (!unfilteredCountries) return;

    setUpdateLoading(true);

    const countryKey = unfilteredCountries.find(
      (country) =>
        t(`countries.${country.id}`, { defaultValue: country.id }) ===
        data.managedCountry,
    )!.id;

    const res = await updateCountryAdmin({
      id: activeUser.id,
      managedCountries: [countryKey],
      isActiveAdmin: data.isActiveAdmin,
    }).finally(() => setUpdateLoading(false));

    if (res.isSuccessful) {
      await queryClient.invalidateQueries({
        queryKey: countryAdminsQuery.queryKey,
      });

      return onCloseModal();
    }

    showToast(
      t('admin.userManagement.addUser.generalError'),
      'error',
      cloudMessageTranslation(res.response.message),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' gap='6' onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack gap='1'>
          <Text color='lighterText'>{activeUser.email}</Text>
          <Text fontSize='2xl' fontWeight='bold'>
            {activeUser.firstName} {activeUser.lastName}
          </Text>
        </Stack>

        <CountryInput
          dropdownContent={() => {
            return (
              <Stack pt='2' pb='6px'>
                {filteredCountries?.length === 0 && (
                  <Text px='4' py='6px' color='lighterText' fontSize='sm'>
                    {t('admin.userManagement.addUser.noResults')}
                  </Text>
                )}
                {filteredCountries?.map((country) => (
                  <Button
                    key={country.id}
                    px='4'
                    py='6px'
                    fontSize='sm'
                    fontWeight='normal'
                    textAlign='left'
                    _hover={{ bg: 'bodyBackground' }}
                    cursor='pointer'
                    onClick={() => {
                      formMethods.setValue(
                        'managedCountry',
                        t(`countries.${country.id}`, {
                          defaultValue: country.id,
                        }),
                      );
                      setTimeout(() => onClose(), 0);
                    }}
                    variant='unstyled'
                  >
                    {t(`countries.${country.id}`, { defaultValue: country.id })}
                  </Button>
                ))}
              </Stack>
            );
          }}
          dropdownOpen={isOpen}
          toggleDropdown={(open) => {
            if (open) {
              onOpen();
              formMethods.setValue('managedCountry', '');
            } else {
              if (formMethods.getValues('managedCountry') === '') {
                formMethods.setValue(
                  'managedCountry',
                  t(`countries.${activeUser.managedCountries[0]}`),
                );
              }
              onClose();
            }
          }}
          accessor='managedCountry'
          label={t('admin.userManagement.addUser.countryLabel')}
          placeholder={t('admin.userManagement.addUser.countryPlaceholder')}
          registerOptions={{
            required: t(
              'admin.userManagement.addUser.errorMessages.isRequired',
            ),
            validate: {
              countryExists: (v) =>
                unfilteredCountries?.some(
                  (country) =>
                    t(`countries.${country.id}`, {
                      defaultValue: country.id,
                    }) === v,
                ) ||
                t('admin.userManagement.addUser.errorMessages.invalidCountry'),
            },
          }}
        />

        <HStack justify='space-between' w='full'>
          <Text>{t('admin.userManagement.admin')}</Text>
          <Box>
            <ActiveSwitch accessor='isActiveAdmin' />
          </Box>
        </HStack>

        <HStack justifyContent='space-between' w='full' mt='4'>
          <Button
            color='lighterText'
            fontSize='sm'
            isDisabled={!formMethods.formState.isValid}
            isLoading={updateLoading}
            type='submit'
            variant='ghost'
          >
            {t('admin.userManagement.editUser.save')}
          </Button>
          <Button
            px='6'
            py='3'
            fontSize='sm'
            onClick={() => setModalType('delete_admin')}
            variant='danger'
          >
            {t('admin.userManagement.editUser.delete')}
          </Button>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
