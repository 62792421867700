import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { ProducerList } from '$/pages/AdminPages/pages/ProducersPage/components/ProducerList';

export const AdminProducersPage = () => {
  const { t } = useTranslation();

  return (
    <Stack gap='4' overflowY='auto' w='full' h='full' p='8'>
      <GeneralAdminHeader
        title={t('admin.producers.changeOrder')}
        description={t('admin.producers.changeOrderDescription')}
      />
      <Stack overflowX='auto' h='full' id='producerContainer'>
        <ProducerList />
      </Stack>
    </Stack>
  );
};
