import {
  DndContext,
  rectIntersection,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  CollisionDetection,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { FC, ReactNode } from 'react';

type DnDModifier =
  | 'limitToVerticalAxis'
  | 'limitToHorizontalAxis'
  | 'restrictToWindowEdges'
  | 'restrictToFirstScrollableAncestor'
  | 'restrictToParentElement';

interface Props {
  onDragEnd: (event: DragEndEvent) => void;
  onDragStart?: (event: DragStartEvent) => void;
  children: ReactNode;
  dragPlaceholder?: ReactNode;
  modifiers?: DnDModifier[];
  collisionDetection?: CollisionDetection;
  useKeyboardSensor?: boolean;
}

const loadModifiers = (modifiers: DnDModifier[]) => {
  const loadedModifiers = [];

  if (modifiers.includes('limitToVerticalAxis')) {
    loadedModifiers.push(restrictToVerticalAxis);
  }

  if (modifiers.includes('limitToHorizontalAxis')) {
    loadedModifiers.push(restrictToHorizontalAxis);
  }

  if (modifiers.includes('restrictToWindowEdges')) {
    loadedModifiers.push(restrictToWindowEdges);
  }

  if (modifiers.includes('restrictToFirstScrollableAncestor')) {
    loadedModifiers.push(restrictToFirstScrollableAncestor);
  }

  if (modifiers.includes('restrictToParentElement')) {
    loadedModifiers.push(restrictToParentElement);
  }

  return loadedModifiers;
};

export const DnDContext: FC<Props> = ({
  onDragEnd,
  onDragStart,
  children,
  dragPlaceholder,
  modifiers = [],
  collisionDetection = rectIntersection,
  useKeyboardSensor = true,
}) => {
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 500, distance: 0 },
    }),
    useKeyboardSensor ? keyboardSensor : undefined,
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      modifiers={loadModifiers(modifiers)}
    >
      {children}
      {dragPlaceholder && <DragOverlay>{dragPlaceholder}</DragOverlay>}
    </DndContext>
  );
};
