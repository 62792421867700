import { convertRotation } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult/RotationConverter';
import { Layer } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import {
  buildUrl,
  cnf,
  imageEndpoints,
  NullableUrlSearchParams,
} from '$/services/endpoints';

export const imageEndpointFactory = {
  sceneImageUrl: ({
    id,
    view,
    width,
    height,
  }: {
    id: string;
    view?: string | number;
    width?: string;
    height?: string;
  }) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      sid: id,
      w: width ?? '1000',
      h: height ?? '1000',
      view: view?.toString() ?? '0',
    };
    return buildUrl(imageEndpoints.SCENE_IMAGE_URL, params);
  },
  materialImageUrl: ({
    id,
    width = '50',
    height = '50',
  }: {
    id: string;
    width?: string;
    height?: string;
  }) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      mid: id,
      w: width,
      h: height,
    };
    return buildUrl(imageEndpoints.MATERIAL_IMAGE_URL, params);
  },
  inspirationRenderScene: ({
    sceneId,
    width,
    height,
    materialIds,
    objectNames,
    view,
    rotations,
  }: {
    sceneId: string;
    width: number;
    height: number;
    materialIds: string;
    objectNames?: string;
    view?: number;
    rotations: string;
  }) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      sid: sceneId,
      w: width.toString(),
      h: height.toString(),
      onam: objectNames,
      mid: materialIds,
      view: view?.toString() ?? '0',
      rot: rotations,
    };
    return buildUrl(imageEndpoints.INSPIRATION_RENDER_SCENE, params);
  },
  projectThumbnail: ({ id }: { id: string }) => {
    const params: NullableUrlSearchParams = { pid: id };
    return buildUrl(imageEndpoints.PROJECT_THUMBNAIL, params);
  },
  favoriteFolderThumbnail: (id: string) => {
    return `${import.meta.env.VITE_FAVORITE_FOLDER_THUMBNAIL_URL}${id}.jpg`;
  },
};

export const buildRenderedInspirationImageUrl = (data: {
  sceneId: string;
  layers: Layer[];
  view?: number;
  resolution?: { width: number; height: number };
}) => {
  const {
    sceneId,
    layers,
    view = 0,
    resolution = { height: 3840, width: 2160 },
  } = data;

  const { materialIds, objectNames, rotations } = layers.reduce<{
    materialIds: string[];
    objectNames: string[];
    rotations: string[];
  }>(
    (accumulator, current) => {
      if (current?.material) {
        accumulator.objectNames.push(current.uniqueName);
        accumulator.materialIds.push(current.material.id);
        accumulator.rotations.push(convertRotation(current.rotation) || '0');
      }
      return accumulator;
    },
    {
      materialIds: [],
      rotations: [],
      objectNames: [],
    },
  );

  return imageEndpointFactory.inspirationRenderScene({
    sceneId,
    width: resolution?.width,
    height: resolution?.height,
    materialIds: materialIds.join(';'),
    objectNames: objectNames.join(';'),
    view,
    rotations: rotations.join(';'),
  });
};

export const buildRetouchPatternImageUrl = ({
  pattern,
  width = 24,
  height = 24,
}: {
  pattern: string;
  width?: number;
  height?: number;
}) => {
  return buildUrl(imageEndpoints.RETOUCH_PATTERN, {
    imname: `${pattern}.jpg`,
    w: width.toString(),
    h: height.toString(),
  });
};
