import { object, z } from 'zod';

import {
  GeneralBackendResponseSchema,
  GeneralVeeuzeAuthResponse,
  parseErrorResult,
} from '$/services/mapper/authentication';

export const GenericVeeuzeCloudResponseSchema = object(
  GeneralVeeuzeAuthResponse,
).transform((data) => {
  return parseErrorResult(data, () => {});
});

const sapCxConnectionStatus = z.enum(['NotYetChecked']);

export const UserSchema = z
  .object({
    id: z.string(),
    email: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    profileImageUrl: z.string().nullable(),
    sapCxConnectionStatus: sapCxConnectionStatus,
    role: z.enum(['free', 'pro', 'superAdmin', 'countryAdmin']).default('pro'),
    managedCountries: z.array(z.string()).default([]),
    lastActivity: z.string().nullable(),
    isActiveAdmin: z.boolean().default(false),
    poEditorPermissions: z.array(z.string()).default([]),
    country: z.string().nullable(),
  })
  .transform((data) => ({
    ...data,
    firstName: data.firstname,
    lastName: data.lastname,
  }));

export type User = z.infer<typeof UserSchema>;

export const RegistrationResponseSchema = z
  .object({
    ...GeneralBackendResponseSchema,
    payload: z
      .object({
        user: UserSchema,
      })
      .optional(),
  })
  .transform((data) => {
    return {
      message: data.message,
      user: data.payload?.user,
    };
  });
