import { Box, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { BrandCountryCard } from '$/pages/AdminPages/pages/CountriesPage/components/BrandCountryCard';
import { EditBrandCountryModal } from '$/pages/AdminPages/pages/CountriesPage/components/EditBrandCountryModal';
import { brandCountriesQuery } from '$/services/usecases/admin/brandCountries';

export const BrandCountryList = () => {
  const countries = useQuery(brandCountriesQuery);
  if (!countries.data?.response.payload) return null;

  return (
    <Box overflowX='auto'>
      <Stack gap='4' minW='800px'>
        {countries.data.response.payload.map((brandCountry) => (
          <BrandCountryCard key={brandCountry.id} country={brandCountry} />
        ))}
        <EditBrandCountryModal />
      </Stack>
    </Box>
  );
};
