import * as React from 'react';

import { Icon } from '$/components/common/Icon';
import { LayerRotation } from '$/services/usecases/projects/mapper/inspirationProjects';

interface RotateIconProps {
  direction: LayerRotation;
}

const rotation: Record<LayerRotation, string> = {
  vertical: 'rotate(0deg)',
  horizontal: 'rotate(90deg)',
  'diagonal-right': 'rotate(45deg)',
  'diagonal-left': 'rotate(135deg)',
};

export const RotateIcon: React.FC<RotateIconProps> = ({
  direction = 'vertical',
}) => {
  return (
    <Icon transform={rotation[direction]} icon='double_arrow' boxSize='4' />
  );
};
