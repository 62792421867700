import { Box, Center, Spinner, Stack, VStack } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { GeneralAdminHeader } from '$/pages/AdminPages/components/GeneralAdminHeader';
import { CollectionListItem } from '$/pages/AdminPages/pages/CollectionsPage/pages/CountryAdminCollectionsPage/components/CollectionListItem';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';
import { collectionsQuery } from '$/services/usecases/collections';

export const CountryAdminCollectionsPage = () => {
  const { t } = useTranslation();
  const brandCountry = useQuery(brandCountriesQuery);
  const collections = useQuery(collectionsQuery);
  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const { mutate, isPending } = useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
    onSuccess: (res) => {
      if (!res.isSuccessful) {
        showToast(
          t('admin.userManagement.addUser.generalError'),
          'error',
          cloudMessageTranslation(res.response.message),
        );
      }
    },
  });

  if (brandCountry.isLoading || collections.isLoading) {
    return (
      <VStack alignItems='start' h='full' p='8'>
        <StyledScrollWrapper>
          <GeneralAdminHeader
            title={t('admin.collections.title')}
            description={t('admin.collections.countryadminDescription')}
          />
          <Center>
            <Spinner />
          </Center>
        </StyledScrollWrapper>
      </VStack>
    );
  }

  const country = brandCountry.data?.response.payload[0];

  if (country == null) return null;

  const sortedCollections = collections.data?.sort(
    (a, b) =>
      country.producerIds.indexOf(a.producerIntName) -
      country.producerIds.indexOf(b.producerIntName),
  );

  const onToggle = (collectionId: string, isActive: boolean) => {
    if (isActive) {
      mutate({
        id: country.id,
        brandCountry: {
          collectionIds: [...country.collectionIds, collectionId].sort(
            (a, b) =>
              (sortedCollections?.findIndex(
                (collection) => collection.intName === a,
              ) ?? 0) -
              (sortedCollections?.findIndex(
                (collection) => collection.intName === b,
              ) ?? 0),
          ),
        },
      });
    } else {
      mutate({
        id: country.id,
        brandCountry: {
          collectionIds: country.collectionIds.filter(
            (collection) => collection !== collectionId,
          ),
        },
      });
    }
  };

  const activeCollections =
    sortedCollections
      ?.filter((collection) =>
        country.collectionIds.includes(collection.intName),
      )
      .map((collection) => collection.intName) ?? [];

  return (
    <Stack alignItems='start' h='full' p='8'>
      <StyledScrollWrapper>
        <GeneralAdminHeader
          title={t('admin.collections.title')}
          description={t('admin.collections.countryadminDescription')}
        />
        <Box overflowX='auto'>
          <Stack gap='4' minW='800px' mt='4'>
            {sortedCollections?.map((collection) => (
              <CollectionListItem
                collection={collection}
                key={collection.intName}
                isActive={activeCollections.includes(collection.intName)}
                onToggle={onToggle}
                isDisabled={isPending}
              />
            ))}
          </Stack>
        </Box>
      </StyledScrollWrapper>
    </Stack>
  );
};
