import { Accordion } from '@chakra-ui/react';
import { DragEndEvent, closestCorners } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import { DnDContext } from '$/components/core/DragAndDrop/DnDContext';
import { SortableContext } from '$/components/core/DragAndDrop/SortableContext';
import { ComponentListItem } from '$/pages/EditorPage/components/ComponentSection/ComponentListItem';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

const moveIndex = (
  oldIndex: number,
  newIndex: number,
  indexToMove: number | null | undefined,
) => {
  if (indexToMove == null) return null;
  if (oldIndex === newIndex) return indexToMove;
  if (indexToMove === oldIndex) return newIndex;
  if (oldIndex < indexToMove && newIndex < indexToMove) return indexToMove;
  if (oldIndex > indexToMove && newIndex > indexToMove) return indexToMove;
  if (oldIndex < indexToMove && newIndex > indexToMove) return indexToMove - 1;
  if (oldIndex > indexToMove && newIndex <= indexToMove) return indexToMove + 1;
};

export const ComponentList = () => {
  const components = useEditorStore.useComponents();
  const openedAccordionIndices = useEditorStore.useOpenedAccordionIndices();
  const setOpenedAccordionIndices =
    useEditorStore.useSetOpenAccordionOpenIndices();
  const moveAccordionItem = useEditorStore.useMoveAccordionItem();
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();

  const { moveComponent, moveObject } = useEditorActions();

  useEffect(() => {
    if (activeComponentIndex == null) {
      return;
    }

    const newIndex = components.length - 1 - activeComponentIndex;

    if (openedAccordionIndices.includes(newIndex)) {
      return;
    }

    setOpenedAccordionIndices([newIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeComponentIndex]);

  const onDragEnd = (result: DragEndEvent) => {
    setDraggingComponentIndex(null);
    console.log(result);

    if (!result.over || result.active.id === result.over.id) {
      return;
    }

    if (result.active.id.toString().includes('component')) {
      const oldIndex = Number.parseInt(
        result.active.id.toString().split('-')[1],
      );
      const newIndex = Number.parseInt(result.over.id.toString().split('-')[1]);

      moveComponent(oldIndex, newIndex);
      moveAccordionItem(oldIndex, newIndex);
      const newOrder = arrayMove(components, oldIndex, newIndex);
      useEditorStore.setState({
        components: newOrder,
        activeComponentIndex: moveIndex(
          oldIndex,
          newIndex,
          activeComponentIndex,
        ),
      });
      return;
    }

    if (result.active.id.toString().includes('layer')) {
      const parentIndex = Number.parseInt(
        result.active.id.toString().split('-')[1],
      );
      const oldIndex = Number.parseInt(
        result.active.id.toString().split('-')[2],
      );
      const newIndex = Number.parseInt(result.over.id.toString().split('-')[2]);

      moveObject(parentIndex, oldIndex, newIndex);
      const newComponents = [...components];
      newComponents[parentIndex].layers = arrayMove(
        newComponents[parentIndex].layers,
        oldIndex,
        newIndex,
      );
      useEditorStore.setState({ components: newComponents });
      return;
    }
  };

  const [draggingComponentIndex, setDraggingComponentIndex] = useState<
    number | null
  >(null);

  return (
    <DnDContext
      onDragEnd={onDragEnd}
      onDragStart={(event) => {
        if (event.active.id.toString().includes('component')) {
          setDraggingComponentIndex(
            Number.parseInt(event.active.id.toString().split('-')[1]),
          );
          setOpenedAccordionIndices([]);
        }
      }}
      useKeyboardSensor={false}
      modifiers={['limitToVerticalAxis', 'restrictToParentElement']}
      collisionDetection={closestCorners}
      dragPlaceholder={
        draggingComponentIndex != null ? (
          <Accordion opacity={0.2}>
            <ComponentListItem
              component={components[draggingComponentIndex]}
              index={draggingComponentIndex}
              dragIndex={components.length - 1 - draggingComponentIndex}
            />
          </Accordion>
        ) : null
      }
    >
      <SortableContext
        itemIds={components.map((component) => `component-${component.index}`)}
        sortingStrategy='vertical'
      >
        <Accordion
          w='full'
          allowToggle
          index={openedAccordionIndices}
          onChange={(newIndices) =>
            setOpenedAccordionIndices([newIndices as number])
          }
        >
          {components
            .slice()
            .reverse()
            .map((component, index) => (
              <ComponentListItem
                key={component.index}
                component={component}
                index={component.index}
                dragIndex={index}
              />
            ))}
        </Accordion>
      </SortableContext>
    </DnDContext>
  );
};
