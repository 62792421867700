import { dashboardColorTokens } from '$/theme/tokens/colors/dashboard';
import { editorColorTokens } from '$/theme/tokens/colors/editor';

export const semanticTokens = {
  colors: {
    ...editorColorTokens,
    ...dashboardColorTokens,
    text: {
      default: 'neutral.1000',
      _dark: 'neutral.50',
    },
    neutralText: {
      default: 'neutral.600',
      _dark: 'neutral.200',
    },
    ultralightText: {
      default: 'neutral.700',
      _dark: 'neutral.200',
    },
    lighterText: {
      default: 'neutral.800',
      _dark: 'neutral.200',
    },
    reverseText: {
      default: 'neutral.50',
      _dark: 'neutral.1000',
    },
    paginationText: {
      default: 'neutral.700',
      _dark: 'neutral.300',
    },
    description: {
      default: 'neutral.800',
      _dark: 'neutral.200',
    },
    bodyBackground: {
      default: 'neutral.100',
      _dark: 'neutral.950',
    },
    background: {
      default: 'neutral.50',
      _dark: 'neutral.1000',
    },
    activeBackground: {
      default: 'neutral.150',
      _dark: 'neutral.850',
    },
    secondaryBackground: {
      default: 'neutral.200',
      _dark: 'neutral.850',
    },
    border: {
      default: 'neutral.300',
      _dark: 'neutral.700',
    },
    borderHover: {
      default: 'neutral.700',
      _dark: 'neutral.200',
    },
    borderActive: {
      default: 'neutral.1000',
      _dark: 'neutral.50',
    },
    buttonHover: {
      default: 'gray.100',
      _dark: 'whiteAlpha.200',
    },
    placeholderText: {
      default: 'neutral.600',
      _dark: 'neutral.400',
    },
    selectedText: {
      default: 'primaryBlue.900',
      _dark: 'primaryBlue.100',
    },
    card: {
      default: '#fff',
      _dark: '#000',
    },
    mobileStackDivider: {
      default: 'neutral.150',
      _dark: 'neutral.950',
    },
    primaryButton: {
      background: {
        default: 'primaryBlue.700',
        _dark: 'primaryBlue.300',
      },
      lightBackground: {
        default: 'primaryBlue.100',
        _dark: 'primaryBlue.900',
      },
      color: {
        default: 'neutral.50',
        _dark: 'neutral.1000',
      },
      hoverBackground: {
        default: 'primaryBlue.800',
        _dark: 'primaryBlue.400',
      },
      activeBackground: {
        default: 'primaryBlue.900',
        _dark: 'primaryBlue.500',
      },
      disabledBackground: {
        default: 'primaryBlue.700',
        _dark: 'primaryBlue.300',
      },
    },
    secondaryButton: {
      background: {
        default: 'neutral.100',
        _dark: 'neutral.950',
      },
      border: {
        default: 'neutral.300',
        _dark: 'neutral.700',
      },
      alternativeBorder: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      color: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      hoverBackground: {
        default: 'neutral.200',
        _dark: 'neutral.800',
      },
      activeBackground: {
        default: 'neutral.300',
        _dark: 'neutral.700',
      },
      disabledColor: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      disabledBackground: {
        default: 'neutral.100',
        _dark: 'neutral.950',
      },
    },
    textButton: {
      color: {
        default: 'neutral.800',
        _dark: 'neutral.300',
      },
      hoverColor: {
        default: 'neutral.900',
        _dark: 'neutral.150',
      },
      activeColor: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      disabledColor: {
        default: 'neutral.800',
        _dark: 'neutral.300',
      },
    },
    blackButton: {
      color: {
        default: 'neutral.50',
        _dark: 'neutral.1000',
      },
      background: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      hoverBackground: {
        default: 'neutral.850',
        _dark: 'neutral.150',
      },
      activeBackground: {
        default: 'neutral.800',
        _dark: 'neutral.200',
      },
      disabledBackground: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      disabledColor: {
        default: 'neutral.50',
        _dark: 'neutral.1000',
      },
    },
    filterButton: {
      background: {
        default: 'badgeLight.red.background',
        _dark: 'badgeDark.red.background',
      },
      color: {
        default: 'neutral.50',
        _dark: 'neutral.1000',
      },
    },
    reverseButton: {
      background: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      color: {
        default: 'neutral.50',
        _dark: 'neutral.1000',
      },
    },
    themeToggle: {
      background: {
        default: 'neutral.100',
        _dark: 'neutral.950',
      },
      activeBackground: {
        default: 'primaryBlue.700',
        _dark: 'primaryBlue.300',
      },
      knob: {
        default: '#fff',
        _dark: 'neutral.700',
      },
    },
    header: {
      divider: {
        default: 'neutral.200',
        _dark: 'neutral.800',
      },
    },
    form: {
      inputBackground: {
        default: 'neutral.100',
        _dark: 'neutral.950',
      },
      inputBorder: {
        default: 'neutral.300',
        _dark: 'neutral.700',
      },
      checkboxBorder: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      activeBorder: {
        default: 'primaryBlue.700',
        _dark: 'primaryBlue.300',
      },
      errorBorder: {
        default: 'danger.900',
        _dark: 'danger.950',
      },
      successBorder: {
        default: 'success.500',
        _dark: 'success.500',
      },
      errorText: {
        default: 'danger.900',
        _dark: 'danger.950',
      },
    },
    absoluteColor: {
      default: 'white',
      _dark: 'black',
    },
  },
};
