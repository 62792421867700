import { HStack, Tooltip, IconButton, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';

interface Props {
  onClick: () => void;
  hideAddButton?: boolean;
}

export const AddComponentSection: FC<Props> = ({
  onClick,
  hideAddButton = false,
}) => {
  return (
    <HStack
      justify='space-between'
      w='full'
      px='4'
      py='2'
      fontSize='sm'
      fontWeight='bold'
    >
      <Text fontWeight='bold'>{t('editor.componentListHeader')}</Text>
      {!hideAddButton && (
        <Tooltip
          bg='background'
          border='1px'
          borderColor='neutral.50'
          shadow='base'
          hasArrow
          label={t('editor.createNewComponent')}
          openDelay={500}
        >
          <IconButton
            minW='0'
            h='auto'
            aria-label={t('editor.addComponentIconAlt')}
            icon={
              <Icon fill='editor.icon' pointerEvents='none' icon='add_layer' />
            }
            onClick={onClick}
            variant='text'
          />
        </Tooltip>
      )}
    </HStack>
  );
};
